import React from 'react'
import { ScaleLoader } from 'react-spinners'

const CardBoard = ({title, value, css}) => (
  <div className={"card text-center text-white bg-" + css}>
    <div className="card-body">
      <h4 className="card-title">{title}</h4>
      {
        value ?
          <div className="card-text font-weigh-bold" style={{fontSize: 50}}>{value}</div>
          :
          <div className="text-center"><ScaleLoader color={"white"} /></div>
      }
    </div>
  </div>
)

export default CardBoard
