import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { loginUser } from '../../../actions/authActions'
import { BarLoader } from 'react-spinners';

class Login extends Component {

  constructor(props) {
    super(props)
    this.state={
      email : '',
      password : '',
      errors: {}
    }

    this.login = this.login.bind(this)
    this.changeInput = this.changeInput.bind(this)
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/')
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.auth.isAuthenticated) {
        this.props.history.push('/')
    }

    if(nextProps.errors){
        this.setState({ errors: nextProps.errors })
    }
  }

  changeInput(e) {
    var name = e.target.name
    var value = e.target.value
    this.setState({
      [name] : value
    })
  }

  login() {
    const userData = {
      email: this.state.email,
      password: this.state.password
    }

    this.props.loginUser(userData)
  }

  render() {
    const {errors} = this.state
    const {auth} = this.props
    return (
      <div className="animated fadeIn mx-auto" style={{maxWidth: 350, marginTop: 100}}>
        <h1>L'Officiel - Administration</h1>
        <div className="m-auto">
          <div className="form-group">
            <small className="form-text text-muted">Entrez votre identifiant administrateur</small>
            <input type="email" name="email" className="form-control" aria-describedby="emailHelp" placeholder="Identifiant" 
                  onChange={this.changeInput}
                  value={this.state.email} 
            />
            <small className="form-text text-danger">{errors.email}</small>
          </div>
          <div className="form-group mb-5">
            <small className="form-text text-muted">Entrez votre mot de passe</small>
            <input type="password" name="password" className="form-control" placeholder="Mot de passe" 
                  onChange={this.changeInput}
                  value={this.state.password}   
            />
            <small className="form-text text-danger">{errors.password}</small>
          </div>
          <div className="text-center">
            {
              auth.isLoading ? (
                <BarLoader
                  widthUnit={"%"}
                  width={100}
                  color={'#bb3234'}
                />) : (
                <button onClick={this.login} className="btn btn-lqo btn-lqo-primary">ME CONNECTER</button>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, { loginUser })(withRouter(Login));