import { LOAD_CONTRACTS, GET_CONTRACTS, SET_CONTRACT_PAID, CREATE_CONTRACT } from '../actions/types'

const initialState = {
    items: [],
    isLoading: false
}

export default function(state = initialState, action) {
    switch (action.type) {
        case LOAD_CONTRACTS:
            return {
                ...state,
                // items: [],
                isLoading: action.payload
            }
            break;
        case GET_CONTRACTS:
            return {
                ...state,
                items: action.payload,
                isLoading: false
            }
            break;
        case SET_CONTRACT_PAID:
            state.items[state.items.findIndex(c => c.id === action.payload.id)] = action.payload
            return {
                ...state,
                items: state.items,
                isLoading: false
            }
            break;
        case CREATE_CONTRACT:
            return {
                ...state,
                items: [action.payload, ...state.items],
                isLoading: false
            }
            break;
    
        default:
            return state
    }
}