import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { ScaleLoader } from 'react-spinners'
import CardBoard from '../../Components/CardBoard'
import { getTotalUsers } from '../../../actions/userActions'
import { getTotalLegalAds } from '../../../actions/legalAdActions'
import { getTotalPublicMarkets } from '../../../actions/publicMarketActions'
import { getCurrentContracts } from '../../../actions/contractActions'

class Dashboard extends Component {
  
  constructor(props) {
    super(props);
  }

  componentDidMount()
  {
    this.props.getTotalUsers()
    this.props.getTotalLegalAds()
    this.props.getTotalPublicMarkets()
    // this.props.getCurrentContracts()
  }

  render() {

    const {users, legalAds, publicMarkets, contracts} = this.props

    return (
      <div className="animated fadeIn">
        <h1>Dashboard</h1>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4">
            <CardBoard title="Utilisateur" value={users.total} css="success" />
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <CardBoard title="Annonces Légales" value={legalAds.total} css="primary" />
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <CardBoard title="Marchés Publics" value={publicMarkets.total}  css="secondary"/>
          </div>
        </div>

        <hr/>
        
        {/* <h5>Détail utilisateurs</h5>
        {contracts.isLoading ?
          <div className="text-center"><ScaleLoader color={"#BB3234"} /></div>
        :
          <div>
            <p>Il y a actuellement {contracts.items.length} contrats en cours</p>

            <table>
              <caption>Contrats en attente de paiement</caption>
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Prénom</th>
                  <th>Email</th>
                  <th>Statut</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  contracts.items.map(c => (

                  ))
                }
                <tr>
                  <td>Nom</td>
                  <td>Prénom</td>
                  <td>Email</td>
                  <td>Statut</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        } */}

      </div>
    );
  }
}

Dashboard.propTypes = {
  getTotalUsers: PropTypes.func.isRequired,
  getTotalLegalAds: PropTypes.func.isRequired,
  getTotalPublicMarkets: PropTypes.func.isRequired,
  getCurrentContracts: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  contracts: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  users: state.users,
  legalAds: state.legalAds,
  publicMarkets: state.publicMarkets,
  contracts: state.contracts,
})

export default connect(mapStateToProps, { getTotalUsers, getTotalLegalAds, getTotalPublicMarkets, getCurrentContracts })(Dashboard)
