import React from "react";

const ConstantEditor = ({
  dataArray,
  varName,
  label,
  updateCst,
  addCst,
  removeCst
}) => {
  return (
    <div className="col-4 mb-5">
      <h4>
        {label}{" "}
        <button
          className="btn btn-primary float-right"
          onClick={() => addCst(varName)}
        >
          <i className="fa fa-plus"></i>
        </button>
      </h4>

      <div
        className="mt-4"
        id={"cst" + varName}
        style={{
          maxHeight: 400,
          overflowY: "scroll",
          width: "100%"
        }}
      >
        {dataArray.map((item, k) => (
          <div key={"cst-" + k + label} className="input-group mb-2">
            <input
              className="form-control"
              style={{ maxWidth: "100%" }}
              type="text"
              name={item.name}
              value={item.name}
              onChange={e => updateCst(varName, k, e.target.value, "name")}
            />
            <div className="input-group-append">
              <input
                className="input-group-text  d-none"
                type="number"
                name={item.id}
                value={item.id}
                onChange={e => updateCst(varName, k, e.target.value, "id")}
              />
              {item.new && (
                <button
                  className="btn btn-danger"
                  onClick={() => removeCst(varName, k)}
                >
                  <i className="fa fa-times" />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConstantEditor;
