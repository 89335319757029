import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUsers } from "../../../../actions/userActions";
import { ScaleLoader } from "react-spinners";
import { DateTime } from "luxon";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { api_url } from "../../../../config/config";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFrom: DateTime.local()
        .startOf("month")
        .toFormat("yyyy-MM-dd"),
      dateTo: DateTime.local().toFormat("yyyy-MM-dd")
    };
    this.changeDate = this.changeDate.bind(this);
  }

  componentDidMount() {
    this.props.getUsers();
  }

  formatUserCell(name, u) {
    return (
      (u.gender == 1 ? "M." : "Mme") + " " + u.lastname + " " + u.firstname
    );
  }

  formatActionCell(id) {
    return (
      <div>
        <Link
          className="btn btn-sm btn-lqo btn-lqo-primary mb-1"
          to={"/users/" + id}
        >
          <i className="fas fa-caret-right" /> MODIFIER
        </Link>
        <br />
        <Link
          className="btn btn-sm btn-lqo btn-lqo-secondary"
          to={"/users/" + id + "/logs"}
        >
          <i className="fas fa-caret-right" /> HISTORIQUE
        </Link>
      </div>
    );
  }

  changeDate(e) {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  render() {
    const { users } = this.props;

    if (users.items) {
      users.items.map(u => {
        u.name =
          (u.gender == 1 ? "M." : "Mme") + " " + u.lastname + " " + u.firstname;
        return u;
      });
    }
    const options = {
      defaultSortName: "createdAt",
      noDataText: "Aucun utilisateur trouvé",
      sizePerPage: 20
    };
    return (
      <div className="animated fadeIn">
        <h1>
          Liste des utilisateurs{" "}
          {users.items && <em>(Totals: {users.items.length})</em>}
        </h1>
        <div className="form-inline">
          <div className="form-group">
            <label className="mx-2">Entre le</label>
            <input
              type="date"
              className="form-control"
              name="dateFrom"
              id="dateFrom"
              value={this.state.dateFrom}
              onChange={this.changeDate}
            />
          </div>
          <div className="form-group">
            <label className="mx-2">et le</label>
            <input
              type="date"
              className="form-control"
              name="dateTo"
              id="dateTo"
              value={this.state.dateTo}
              onChange={this.changeDate}
            />
          </div>
          <button
            className="btn btn-sm btn-lqo btn-lqo-primary mx-2"
            onClick={() =>
              window.open(
                api_url +
                  "csv/" +
                  this.state.dateFrom +
                  "/" +
                  this.state.dateTo +
                  "/" +
                  localStorage.getItem("jwtToken").replace("Bearer ", "")
              )
            }
          >
            Export factures CSV
          </button>
        </div>

        {users.isLoading ? (
          <div className="text-center">
            <ScaleLoader color={"#BB3234"} />
          </div>
        ) : (
          <div>
            {users.items && (
              <BootstrapTable
                containerClass="table"
                data={users.items}
                bordered={false}
                keyField="id"
                options={options}
                searchPlaceholder="Email, Nom, Société, etc..."
                hover
                pagination
                search
                condensed
                ignoreSinglePage
              >
                <TableHeaderColumn dataField="email" dataSort>
                  Email
                </TableHeaderColumn>
                <TableHeaderColumn dataField="name" dataSort>
                  Utilisateur
                </TableHeaderColumn>
                <TableHeaderColumn dataField="company" dataSort>
                  Société
                </TableHeaderColumn>
                <TableHeaderColumn dataField="siren" dataSort>
                  SIREN
                </TableHeaderColumn>
                <TableHeaderColumn dataField="phone" dataSort>
                  Téléphone
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="createdAt"
                  dataFormat={cell =>
                    DateTime.fromISO(cell).toLocaleString(DateTime.DATE_SHORT)
                  }
                  dataSort
                >
                  Date de création
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="id"
                  dataFormat={this.formatActionCell}
                >
                  Action
                </TableHeaderColumn>
              </BootstrapTable>
            )}
          </div>
        )}
      </div>
    );
  }
}

UserList.propTypes = {
  getUsers: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  users: state.users
});

export default withRouter(
  connect(
    mapStateToProps,
    { getUsers }
  )(UserList)
);
