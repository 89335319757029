import axios from 'axios'

import { GET_ERRORS, CLEAR_ERRORS, LOAD_PUBLICMARKETS, GET_PUBLICMARKETS, GET_PUBLICMARKET, UPDATE_PUBLICMARKET, CREATE_PUBLICMARKET, DELETE_PUBLICMARKET, GET_TOTAL_PUBLICMARKET} from './types'
import { api_url } from '../config/config'

// Get all publicmarkets
export const getPublicMarkets = () => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_PUBLICMARKETS, payload: true})
    
    axios.get(api_url + 'publicmarkets')
        .then(res => {
            dispatch({type: GET_PUBLICMARKETS, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_PUBLICMARKETS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}


// Get specific publicmarkets
export const getPublicMarket = (id) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_PUBLICMARKETS, payload: true})
    
    axios.get(api_url + 'publicmarkets/' + id)
        .then(res => {
            dispatch({type: GET_PUBLICMARKET, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_PUBLICMARKETS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Update specific publicmarkets
export const updatePublicMarket = (id, data) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_PUBLICMARKETS, payload: true})
    
    axios.patch(api_url + 'publicmarkets/' + id, data)
        .then(res => {
            dispatch({type: UPDATE_PUBLICMARKET, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_PUBLICMARKETS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Create publicmarkets
export const createPublicMarket = () => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_PUBLICMARKETS, payload: true})
    
    axios.post(api_url + 'publicmarkets', {title: "Nouveau marché public", content: "Contenu"})
        .then(res => {
            dispatch({type: CREATE_PUBLICMARKET, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_PUBLICMARKETS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Delete specific publicmarket
export const deletePublicMarket = (id) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_PUBLICMARKETS, payload: true})
    
    axios.delete(api_url + 'publicmarkets/' + id)
        .then(res => {
            dispatch({type: DELETE_PUBLICMARKET, payload: id})
        })
        .catch(err => {
            dispatch({type: LOAD_PUBLICMARKETS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Get total public markets
export const getTotalPublicMarkets = () => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_PUBLICMARKETS, payload: true})
    
    axios.get(api_url + 'publicmarkets/total')
        .then(res => {
            dispatch({type: GET_TOTAL_PUBLICMARKET, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_PUBLICMARKETS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}
