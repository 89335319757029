import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { changePasswordUser } from '../../actions/userActions'
import { BarLoader } from 'react-spinners';

class ModalChangePassword extends Component {

  constructor(props) {
    super(props)
    this.state={
      newPassword : '',
      newPasswordConfirm : '',
      errors: {}
    }

    this.changePassword = this.changePassword.bind(this)
    this.changeInput = this.changeInput.bind(this)
  }

  changeInput(e) {
    var name = e.target.name
    var value = e.target.value
    this.setState({
      [name] : value
    })
  }

  changePassword() {
    const data = {
      newPassword: this.state.newPassword,
      newPasswordConfirm: this.state.newPasswordConfirm
    }

    this.props.changePasswordUser(this.props.userId, data)
  }

  render() {
    const {users, errors} = this.props

    return (
      <div>
        <button className="btn btn-lqo btn-lqo-secondary" data-toggle="modal" data-target="#modalChangePassword">MODIFIER SON MOT DE PASSE</button>
        
        <div className="modal fade" data-backdrop="false" id="modalChangePassword" tabIndex="-1" role="dialog" >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Changement du mot de passe d'un utilisateur</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closeModalChangePassword">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group mb-5">
                  <small className="form-text text-muted text-left">Entrez le nouveau mot de passe</small>
                  <input type="password" name="newPassword" className="form-control" placeholder="Mot de passe" 
                        onChange={this.changeInput}
                        value={this.state.newPassword}   
                  />
                  <small className="form-text text-danger text-left">{errors.newPassword}</small>
                </div>

                <div className="form-group mb-5">
                  <small className="form-text text-muted text-left">Confirmez le nouveau mot de passe</small>
                  <input type="password" name="newPasswordConfirm" className="form-control" placeholder="Mot de passe" 
                        onChange={this.changeInput}
                        value={this.state.newPasswordConfirm}   
                  />
                  <small className="form-text text-danger text-left">{errors.newPasswordConfirm}</small>
                </div>
              </div>
              <div className="modal-footer">
                {
                  users.isLoading ? (
                    <BarLoader
                      widthUnit={"%"}
                      width={100}
                      color={'#bb3234'}
                    />) : (
                      <div>
                        <button type="button" className="btn btn-lqo-secondary mr-3" data-dismiss="modal">Fermer</button>
                        <button type="button" className="btn btn-lqo-primary" onClick={this.changePassword}>Enregistrer</button>
                      </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

ModalChangePassword.propTypes = {
  changePasswordUser: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  users: state.users,
  errors: state.errors
})

export default connect(mapStateToProps, {changePasswordUser})(ModalChangePassword);