import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getLegalAd, updateLegalAd } from "../../../../actions/legalAdActions";
import storageHelper from "../../../../utils/storageHelper";
import { ScaleLoader } from "react-spinners";
import { DateTime } from "luxon";
import { image_url } from "../../../../config/config";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

class LegalAdDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 1,
      reference: "",
      type: "",
      title: "",
      legalForm: "",
      source: 1,
      newspaperPublishedAt: "",
      liquidator: "",
      webPublishedAt: "",
      content: "",
      createdAt: null,
      image: "",
      preview: {
        status: "",
        reference: "",
        type: "",
        title: "",
        legalForm: "",
        source: "",
        newspaperPublishedAt: "",
        liquidator: "",
        webPublishedAt: "",
        content: "",
        image: ""
      }
    };

    this.updateLegalAd = this.updateLegalAd.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.updatePreview = this.updatePreview.bind(this);
    this.publishLegalAd = this.publishLegalAd.bind(this);
    this.changeContent = this.changeContent.bind(this);
  }

  componentDidMount() {
    this.props.getLegalAd(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp.constants.items && nextProp.legalAds.item) {
      this.setState(nextProp.legalAds.item);
      this.setState({
        preview: nextProp.legalAds.item
      });
    }
  }

  changeInput(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({ [name]: value });
  }

  changeContent(content) {
    this.setState({ content });
  }
  updateLegalAd() {
    this.props.updateLegalAd(this.props.match.params.id, this.state);
  }
  publishLegalAd() {
    this.setState(
      {
        status: 2
      },
      function() {
        this.updateLegalAd();
      }
    );
    //this.props.updateLegalAd(this.props.match.params.id, this.state)
  }

  updatePreview() {
    let preview = { ...this.state };
    delete preview.preview;
    this.setState({
      preview
    });
  }

  render() {
    const { legalAds, errors, constants } = this.props;

    let types = [];
    let type = null;
    let typePreview = null;
    let legForms = [];
    let adsources = [];

    if (constants.items) {
      if (constants.items.typesLA) {
        constants.items.typesLA.forEach((element, k) => {
          types.push(
            <option key={k} value={element.id}>
              {element.name}
            </option>
          );
        });

        type = constants.items.typesLA.find(cat => cat.id == this.state.type);
        typePreview = constants.items.typesLA.find(
          cat => cat.id == this.state.preview.type
        );
      }

      if (constants.items.legalForms) {
        constants.items.legalForms.forEach((el, k) => {
          legForms.push(
            <option key={k} value={el.id}>
              {el.name}
            </option>
          );
        });
      }

      if (constants.items.sources) {
        constants.items.sources.forEach((el, k) => {
          adsources.push(
            <option key={k} value={el.id}>
              {el.name}
            </option>
          );
        });
      }
    }

    let errorsDiv = "";
    if (Object.keys(errors).length != 0 && errors.constructor === Object) {
      console.log("errors", errors);
      errorsDiv = (
        <div style={{ marginTop: 15, color: "#e41619" }}>
          Le formulaire comporte des erreurs
        </div>
      );
    }

    return (
      <div className="animated fadeIn">
        <div>
          <Link className="btn btn-light" to="/legalads">
            <i className="fa fa-chevron-left pr-2"></i> Retour
          </Link>
        </div>
        <h1>Editer une annonce</h1>

        <div className="row mb-5">
          <div className="col-md-6">
            <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">
                Logo
              </label>
              <div className="col-sm-9">
                {this.state.image != "" ? (
                  <img
                    style={{ maxHeight: 100 }}
                    src={storageHelper(this.state)}
                  />
                ) : (
                  <span className="form-text">aucune image</span>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">
                Etat de la publication
              </label>
              <div className="col-sm-9">
                <select
                  id="status"
                  className="form-control"
                  name="status"
                  onChange={this.changeInput}
                  value={this.state.status}
                >
                  <option value="1">Brouillon</option>
                  <option value="2">Publié</option>
                </select>
                <small className="form-text text-danger">{errors.status}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">
                Type
              </label>
              <div className="col-sm-9">
                <select
                  id="type"
                  className="form-control"
                  name="type"
                  onChange={this.changeInput}
                  value={this.state.type}
                >
                  {types}
                </select>
                <small className="form-text text-danger">{errors.type}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">
                Forme juridique
              </label>
              <div className="col-sm-9">
                <select
                  id="legalForm"
                  className="form-control"
                  name="legalForm"
                  onChange={this.changeInput}
                  value={this.state.legalForm}
                >
                  <option value="">...</option>
                  {legForms}
                </select>
                <small className="form-text text-danger">
                  {errors.legalForm}
                </small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">
                Titre
              </label>
              <div className="col-sm-9">
                <input
                  id="title"
                  className="form-control"
                  name="title"
                  onChange={this.changeInput}
                  value={this.state.title}
                />
                <small className="form-text text-danger">{errors.title}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">
                Source
              </label>
              <div className="col-sm-9">
                <select
                  id="source"
                  className="form-control"
                  name="source"
                  onChange={this.changeInput}
                  value={this.state.source}
                >
                  <option value="">...</option>
                  {adsources}
                </select>
                <small className="form-text text-danger">{errors.source}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">
                Parution journal
              </label>
              <div className="col-sm-9">
                <input
                  type="date"
                  id="newspaperPublishedAt"
                  className="form-control"
                  name="newspaperPublishedAt"
                  value={
                    this.state.newspaperPublishedAt &&
                    DateTime.fromISO(this.state.newspaperPublishedAt)
                      .toISO()
                      .substr(0, 10)
                  }
                  onChange={this.changeInput}
                />
                <small className="form-text text-danger">
                  {errors.newspaperPublishedAt}
                </small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">
                Parution web
              </label>
              <div className="col-sm-9">
                <input
                  type="date"
                  id="webPublishedAt"
                  className="form-control"
                  name="webPublishedAt"
                  value={
                    this.state.webPublishedAt &&
                    DateTime.fromISO(this.state.webPublishedAt)
                      .toISO()
                      .substr(0, 10)
                  }
                  onChange={this.changeInput}
                />
                <small className="form-text text-danger">
                  {errors.webPublishedAt}
                </small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">
                Liquidateur
              </label>
              <div className="col-sm-9">
                <input
                  id="liquidator"
                  className="form-control"
                  name="liquidator"
                  onChange={this.changeInput}
                  value={this.state.liquidator}
                />
                <small className="form-text text-danger">
                  {errors.liquidator}
                </small>
              </div>
            </div>

            <div
              className="form-group row"
              style={{ maxHeight: 1000, overflow: "auto" }}
            >
              <label htmlFor="status" className="col-sm-3 col-form-label">
                Contenu
              </label>
              <div className="col-sm-9">
                <CKEditor
                  editor={ClassicEditor}
                  data={this.state.content}
                  onInit={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    this.changeContent(data);
                  }}
                />
                <small className="form-text text-danger">
                  {errors.content}
                </small>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="preview">
              <div className="form-group row">
                <label htmlFor="status" className="col-sm-5 col-form-label">
                  Logo
                </label>
                <div className="col-sm-7">
                  {this.state.preview.image != "" ? (
                    <img
                      style={{ maxHeight: 100 }}
                      src={storageHelper(this.state)}
                    />
                  ) : (
                    <span className="form-text">aucune image</span>
                  )}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="status" className="col-sm-5 col-form-label">
                  Etat de la publication
                </label>
                <div className="col-sm-7">
                  {this.state.preview.status == 2 ? "Publié" : "Brouillon"}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="type" className="col-sm-5 col-form-label">
                  Type
                </label>
                <div className="col-sm-7">
                  {typePreview && typePreview.name}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="reference" className="col-sm-5 col-form-label">
                  Référence
                </label>
                <div className="col-sm-7">{this.state.preview.reference}</div>
              </div>

              <div className="form-group row">
                <label htmlFor="legalForm" className="col-sm-5 col-form-label">
                  Forme juridique
                </label>
                <div className="col-sm-7">
                  {constants.items &&
                  constants.items.legalForms &&
                  this.state.preview.legalForm
                    ? constants.items.legalForms.find(
                        leg => leg.id == this.state.preview.legalForm
                      ).name
                    : ""}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="title" className="col-sm-5 col-form-label">
                  Titre
                </label>
                <div className="col-sm-7">{this.state.preview.title}</div>
              </div>

              <div className="form-group row">
                <label htmlFor="source" className="col-sm-5 col-form-label">
                  Source
                </label>
                <div className="col-sm-7">
                  {constants.items &&
                  constants.items.sources &&
                  this.state.preview.source
                    ? constants.items.sources.find(
                        sour => sour.id == this.state.preview.source
                      ).name
                    : ""}
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="newspaperPublishedAt"
                  className="col-sm-5 col-form-label"
                >
                  Parution journal
                </label>
                <div className="col-sm-7">
                  {DateTime.fromISO(
                    this.state.preview.newspaperPublishedAt
                  ).toLocaleString(DateTime.DATE_SHORT)}
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="webPublishedAt"
                  className="col-sm-5 col-form-label"
                >
                  Parution web
                </label>
                <div className="col-sm-7">
                  {DateTime.fromISO(
                    this.state.preview.webPublishedAt
                  ).toLocaleString(DateTime.DATE_SHORT)}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="liquidator" className="col-sm-5 col-form-label">
                  Liquidateur
                </label>
                <div className="col-sm-7">{this.state.preview.liquidator}</div>
              </div>

              <div
                className="form-group row"
                style={{ maxHeight: 1000, overflow: "auto" }}
              >
                <label htmlFor="status" className="col-sm-5 col-form-label">
                  Contenu
                </label>
                <div className="col-sm-7">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.preview.content
                    }}
                  />
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <button
                  onClick={this.updatePreview}
                  className="btn btn-lqo btn-lqo-primary"
                >
                  Mettre à jour l'aperçu
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center">
          {legalAds.isLoading ? (
            <div className="text-center">
              <ScaleLoader color={"#BB3234"} />
            </div>
          ) : (
            <div>
              {legalAds.isSaved && (
                <div className="text-success mb-1">
                  <i className="fa fa-check mr-1"></i>L'annonce légale a bien
                  été enregistrée
                </div>
              )}
              <button
                onClick={this.updateLegalAd}
                className="btn btn-lqo btn-lqo-primary"
              >
                ENREGISTRER{this.state.status == 1 && " LE BROUILLON"}
              </button>
              {this.state.status == 1 && (
                <button
                  onClick={this.publishLegalAd}
                  className="btn btn-lqo btn-lqo-secondary"
                >
                  PUBLIER
                </button>
              )}
            </div>
          )}
          {errorsDiv}
        </div>
      </div>
    );
  }
}

LegalAdDetail.propTypes = {
  getLegalAd: PropTypes.func.isRequired,
  updateLegalAd: PropTypes.func.isRequired,
  legalAds: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  constants: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  legalAds: state.legalAds,
  errors: state.errors,
  constants: state.constants
});

export default connect(
  mapStateToProps,
  { getLegalAd, updateLegalAd }
)(LegalAdDetail);
