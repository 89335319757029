import { combineReducers } from 'redux'
import authReducer from './authReducer'
import errorReducer from './errorReducer'
import userReducer from './userReducer'
import contractReducer from './contractReducer'
import logReducer from './logReducer'
import legalAdReducer from './legalAdReducer'
import publicMarketReducer from './publicMarketReducer'
import constantReducer from './constantReducer'
import adminReducer from './adminReducer'

export default combineReducers({
    auth: authReducer,
    errors: errorReducer,
    users: userReducer,
    contracts: contractReducer,
    logs: logReducer,
    legalAds: legalAdReducer,
    publicMarkets: publicMarketReducer,
    constants: constantReducer,
    admins: adminReducer,
})