import React, { Component } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
// Components
import DefaultFooter from "./views/Components/Layout/DefaultFooter";
import DefaultHeader from "./views/Components/Layout/DefaultHeader";
import Error from "./views/Components/Error";
import Menu from "./views/Components/Layout/Menu";
import PrivateRoute from "./views/Components/PrivateRoute";
// Pages
import { Login, Page404 } from "./views/Pages";
import Dashboard from "./views/Pages/Private/Dashboard";
import AdminList from "./views/Pages/Private/Admin/AdminList";
import AdminDetail from "./views/Pages/Private/Admin/AdminDetail";
import UserList from "./views/Pages/Private/User/UserList";
import UserDetail from "./views/Pages/Private/User/UserDetail";
import UserLog from "./views/Pages/Private/User/UserLog";
import LegalAdList from "./views/Pages/Private/LegalAd/LegalAdList";
import LegalAdDetail from "./views/Pages/Private/LegalAd/LegalAdDetail";
import PublicMarketList from "./views/Pages/Private/PublicMarket/PublicMarketList";
import PublicMarketDetail from "./views/Pages/Private/PublicMarket/PublicMarketDetail";
//Redux
import jwt_decode from "jwt-decode";
import { SET_CURRENT_USER } from "./actions/types";
import { logoutUser } from "./actions/authActions";
import { getConstants } from "./actions/constantActions";
import setAuthToken from "./utils/setAuthToken";

//FIXES REACT-BOOTSTRAP-TABLE
//on importe ce css sinon les head ne sont pas aligner avec le content body
import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import ConstantDetail from "./views/Pages/Private/Constant/ConstantDetail";

// Check for token user
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch({ type: SET_CURRENT_USER, payload: decoded });

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user

    store.dispatch(logoutUser());
    // Clear current Profile
    // store.dispatch(clearCurrentProfile())
    // Redirect to login
    window.location.href = "/login";
  }
}

//chargement des constantes
store.dispatch(getConstants());

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="animated fadeIn">
            <DefaultHeader />
            <Switch>
              <Route exact path="/login" component={Login} />
            </Switch>
            <main role="main" className="container-fluid my-5">
              <div className="row">
                <div className="col-12">
                  <Error />
                  <Switch>
                    <PrivateRoute exact path="/" component={Dashboard} />
                    <PrivateRoute exact path="/admins" component={AdminList} />
                    <PrivateRoute
                      exact
                      path="/admins/:id"
                      component={AdminDetail}
                    />
                    <PrivateRoute exact path="/users" component={UserList} />
                    <PrivateRoute
                      exact
                      path="/users/:id"
                      component={UserDetail}
                    />
                    <PrivateRoute
                      exact
                      path="/users/:id/logs"
                      component={UserLog}
                    />
                    <PrivateRoute
                      exact
                      path="/legalads"
                      component={LegalAdList}
                    />
                    <PrivateRoute
                      exact
                      path="/legalads/:id"
                      component={LegalAdDetail}
                    />
                    <PrivateRoute
                      exact
                      path="/constants"
                      component={ConstantDetail}
                    />
                    <PrivateRoute
                      exact
                      path="/publicmarkets"
                      component={PublicMarketList}
                    />
                    <PrivateRoute
                      exact
                      path="/publicmarkets/:id"
                      component={PublicMarketDetail}
                    />
                  </Switch>
                </div>
              </div>
            </main>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
