import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { getAdmin, updateAdmin, createAdmin } from '../../../../actions/adminActions'
import { ScaleLoader } from 'react-spinners'

class AdminDetail extends Component {

  constructor(props) {
    super(props)
    this.state = {
      admin: {
        email: "",
        lastname: "",
        firstname: "",
        password: "",
        role: "ROLE_ADMIN",
        id: ""
      }
    }

    this.createAdmin = this.createAdmin.bind(this)
    this.updateAdmin = this.updateAdmin.bind(this)
    this.changeInput = this.changeInput.bind(this)
  }

  componentDidMount()
  {
    let id = this.props.match.params.id
    if(id !== "create") this.props.getAdmin(this.props.match.params.id)
  }

  componentWillReceiveProps(nextProp)
  {
    if(nextProp.admins.item){
      var admin = {... this.state.admin}
      for (let k in this.state.admin) {
        if(nextProp.admins.item[k])admin[k] =  nextProp.admins.item[k]
      }
      this.setState({admin: admin})
    }
  }

  changeInput(e) {
    var name = e.target.name
    var value = e.target.value
    var admin = {... this.state.admin}
    admin[name] = value
    this.setState({admin})
  }

  updateAdmin() {
    this.props.updateAdmin(this.props.match.params.id, this.state.admin)
  }
  
  createAdmin() {
    this.props.createAdmin(this.state.admin)
  }

  render() {
    const {admins, errors} = this.props
    const {admin} = this.state
    
    return (
      <div className="animated fadeIn">
        <div>
          <Link className="btn btn-light" to="/admins">
            <i className="fa fa-chevron-left pr-2"></i> Retour
          </Link>
        </div>
        <h1>Modifier un compte administrateur</h1>

        <div className="row mb-5">

          <div className="col-12">

            <div className="form-group row">
              <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
              <div className="col-sm-9">
                <input type="text" className="form-control" name="email" id="email" placeholder="Ex: moi@exemple.com"
                  onChange={this.changeInput} value={admin.email} />
                <small className="form-text text-danger">{errors.email}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="password" className="col-sm-2 col-form-label">Password</label>
              <div className="col-sm-9">
              <small className="form-text text-danger">Laisser vide si vous ne souhaitez pas modifier le mot de passe</small>
                <input type="password" className="form-control" name="password" id="password"
                  onChange={this.changeInput} value={admin.password} />
                <small className="form-text text-danger">{errors.password}</small>
              </div>
            </div>
          </div>

          <div className="col-12">

            <div className="form-group row">
              <label htmlFor="role" className="col-sm-2 col-form-label">Rôle</label>
              <div className="col-sm-9">
                <select id="role" className="form-control" name="role" onChange={this.changeInput} value={admin.role}>
                  <option value="ROLE_SUPER_ADMIN">Super Admin</option>
                  <option value="ROLE_ADMIN">Admin</option>
                </select>
                <small className="form-text text-danger">{errors.role}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="lastname" className="col-sm-2 col-form-label">Nom</label>
              <div className="col-sm-9">
                <input type="text" className="form-control" id="lastname" name="lastname" placeholder="Ex: Payet"
                  onChange={this.changeInput} value={admin.lastname} />
                <small className="form-text text-danger">{errors.lastname}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="firstname" className="col-sm-2 col-form-label">Prénom</label>
              <div className="col-sm-9">
                <input type="text" className="form-control" id="firstname" name="firstname" placeholder="Ex: Alexandre"
                  onChange={this.changeInput} value={admin.firstname} />
                <small className="form-text text-danger">{errors.firstname}</small>
              </div>
            </div>

          </div>

        </div>


        <div className="text-center">
          {admins.isLoading ? 
            <ScaleLoader className="text-center" color={"#BB3234"} />
            : 
            <div>
              {admins.isSaved && <div className="text-success mb-1"><i className="fa fa-check mr-1"></i>L'administrateur a bien été mis à jour</div>}
              {
                admin.id ? 
                <button onClick={this.updateAdmin} className="btn btn-lqo btn-lqo-primary">ENREGISTRER</button>
                :
                <button onClick={this.createAdmin} className="btn btn-lqo btn-lqo-primary">CRÉER</button>
              }
            </div>
          }
        </div>

      </div>
    );
  }
}

AdminDetail.propTypes = {
  getAdmin: PropTypes.func.isRequired,
  updateAdmin: PropTypes.func.isRequired,
  createAdmin: PropTypes.func.isRequired,
  admins: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  admins: state.admins,
  errors: state.errors,
})

export default connect(mapStateToProps, { getAdmin, updateAdmin, createAdmin })(AdminDetail);