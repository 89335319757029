import axios from 'axios'
import setAuthToken from '../utils/setAuthToken'
import jwt_decode from 'jwt-decode'

import { GET_ERRORS, CLEAR_ERRORS, SET_CURRENT_USER, LOAD_CURRENT_USER, DELETE_CURRENT_USER} from './types'
import { api_url } from '../config/config'

// Login User
export const loginUser = userData => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_CURRENT_USER, payload: true})
    
    axios.post(api_url + 'auth/admin/login', userData)
        .then(res => {
            // Save to localStorage
            const { token } = res.data
            // Set token to ls
            localStorage.setItem('jwtToken', token)
            // Set token to Auth header
            setAuthToken(token)
            // Set current user
            dispatch({type: SET_CURRENT_USER, payload: jwt_decode(token)})
        })
        .catch(err => {
            dispatch({type: LOAD_CURRENT_USER, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Log user out
export const logoutUser = () => dispatch => {
    // Remove token from localStorage
    localStorage.removeItem('jwtToken');
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to {} which will set isAuthenticated to false
    dispatch({type: DELETE_CURRENT_USER, payload: {}})
};
