import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { getPublicMarkets, createPublicMarket, deletePublicMarket } from '../../../../actions/publicMarketActions'
import { ScaleLoader } from 'react-spinners'
import { DateTime } from 'luxon'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import Axios from 'axios';
import { api_url } from '../../../../config/config';

class PublicMarketList extends Component {

  constructor(props) {
    super(props)
    this.state={}

    this.formatTypeCell = this.formatTypeCell.bind(this)
    this.formatCategoriesCell = this.formatCategoriesCell.bind(this)
    this.formatActionCell = this.formatActionCell.bind(this)
  }

  componentDidMount()
  {
    this.props.getPublicMarkets()
  }

  formatStatusCell(status)
  {
    return status == 1 ? <span className="text-secondary">Brouillon</span> : <span className="text-success">Publié</span>
  }

  formatCategoriesCell(categories)
  {
    let r = []
    for (const c of categories) {
      const cat = this.props.constants.items.categoriesMP.find(x => x.id == c)
      if(cat) r.push(cat.name)  
    }

    return r.length > 0 ? r.join(', ') : 'Aucun type'
  }

  formatTypeCell(type)
  {
    let find = this.props.constants.items.typesMP.find(c => c.id == type)
    return find ? find.name : "Aucun type"
  }

  formatActionCell(id)
  {
    return <div>
      <Link className="btn btn-sm btn-lqo btn-lqo-secondary mb-1" to={"/publicmarkets/" + id}>
        <i className="fas fa-caret-right"></i> MODIFIER
      </Link>
      <br/>
      <button className="btn btn-sm btn-lqo btn-lqo-primary"
        onClick={()=>{this.props.deletePublicMarket(id)}}>
        <i className="fas fa-caret-right"></i> SUPPRIMER
      </button>
    </div>
  }

  render() {
    const {publicMarkets} = this.props
    const options = {
      defaultSortName: 'createdAt',
      noDataText: 'Aucun marché public trouvé',
      sizePerPage: 20
    }

    return (
      <div className="animated fadeIn">
        <h1>Liste des marchés publics {publicMarkets.items && <em>(Totals: {publicMarkets.items.length})</em>}</h1>
        <div className="mb-3">
          <button className="btn btn-lqo btn-lqo-green"
            onClick={()=>{this.props.createPublicMarket()}}>
            <i className="fas fa-caret-right"></i> CREER UN NOUVEAU MARCHÉ PUBLIC
          </button>
        </div>
        

        {publicMarkets.isLoading ? 
          <div className="text-center"><ScaleLoader color={"#BB3234"} /></div>
          :
          (
            <div>
              {publicMarkets.items && 
                <BootstrapTable containerClass="table" data={publicMarkets.items} bordered={false} keyField="id" options={options}
                  searchPlaceholder='Rechercher...'
                  hover pagination search condensed ignoreSinglePage>
                  <TableHeaderColumn dataField="status" dataFormat={this.formatStatusCell} dataSort>Etat</TableHeaderColumn>
                  <TableHeaderColumn dataField="reference" dataSort>Ref.</TableHeaderColumn>
                  <TableHeaderColumn dataField="type" dataFormat={this.formatTypeCell} dataSort>Type</TableHeaderColumn>
                  <TableHeaderColumn dataField="title"dataSort>Titre</TableHeaderColumn>
                  <TableHeaderColumn dataField="categories" dataFormat={this.formatCategoriesCell} dataSort>Type de marché</TableHeaderColumn>
                  <TableHeaderColumn dataField="createdAt" dataFormat={cell => DateTime.fromISO(cell).toLocaleString(DateTime.DATE_SHORT)} dataSort>Date de création</TableHeaderColumn>
                  <TableHeaderColumn dataField="id" dataFormat={this.formatActionCell}>Action</TableHeaderColumn>
                </BootstrapTable>
              }
            </div>
          )
        }
      </div>
    )
  }
}

PublicMarketList.propTypes = {
  getPublicMarkets: PropTypes.func.isRequired,
  createPublicMarket: PropTypes.func.isRequired,
  deletePublicMarket: PropTypes.func.isRequired,
  publicMarkets: PropTypes.object.isRequired,
  constants: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  publicMarkets: state.publicMarkets,
  constants: state.constants,
})

export default connect(mapStateToProps, { getPublicMarkets, createPublicMarket, deletePublicMarket })(PublicMarketList)