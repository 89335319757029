import axios from 'axios'

import { GET_ERRORS, CLEAR_ERRORS, LOAD_LEGALADS, GET_LEGALADS, GET_LEGALAD, UPDATE_LEGALAD, CREATE_LEGALAD, DELETE_LEGALAD, GET_TOTAL_LEGALAD} from './types'
import { api_url } from '../config/config'

// Get all legalads
export const getLegalAds = () => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_LEGALADS, payload: true})
    
    axios.get(api_url + 'legalads')
        .then(res => {
            dispatch({type: GET_LEGALADS, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_LEGALADS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}


// Get specific legalads
export const getLegalAd = (id) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_LEGALADS, payload: true})
    
    axios.get(api_url + 'legalads/' + id)
        .then(res => {
            dispatch({type: GET_LEGALAD, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_LEGALADS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Update specific legalads
export const updateLegalAd = (id, data) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_LEGALADS, payload: true})
    
    axios.patch(api_url + 'legalads/' + id, data)
        .then(res => {
            dispatch({type: UPDATE_LEGALAD, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_LEGALADS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Create legalads
export const createLegalAd = () => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_LEGALADS, payload: true})
    
    axios.post(api_url + 'legalads', {title: "Nouvelle annonce légale", content: "Contenu"})
        .then(res => {
            dispatch({type: CREATE_LEGALAD, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_LEGALADS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Delete specific legalad
export const deleteLegalAd = (id) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_LEGALADS, payload: true})
    
    axios.delete(api_url + 'legalads/' + id)
        .then(res => {
            dispatch({type: DELETE_LEGALAD, payload: id})
        })
        .catch(err => {
            dispatch({type: LOAD_LEGALADS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Get total legalads
export const getTotalLegalAds = () => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_LEGALADS, payload: true})
    
    axios.get(api_url + 'legalads/total')
        .then(res => {
            dispatch({type: GET_TOTAL_LEGALAD, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_LEGALADS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

