import { LOAD_USERS, GET_USERS, GET_USER, UPDATE_USER, GET_TOTAL_USER } from '../actions/types'

const initialState = {
    items: null,
    item : null,
    isLoading: false,
    isSaved: false,
    total: null
}

export default function(state = initialState, action) {
    switch (action.type) {
        case LOAD_USERS:
            return {
                ...state,
                items: null,
                item: null,
                isLoading: action.payload,
                isSaved: false,
                total: null
            }
            break;
        case GET_USERS:
            return {
                ...state,
                items: action.payload,
                item: null,
                isLoading: false,
                isSaved: false
            }
            break;
        case GET_USER:
            return {
                ...state,
                items: null,
                item: action.payload,
                isLoading: false,
                isSaved: false
            }
            break;
        case GET_TOTAL_USER:
            return {
                ...state,
                total: action.payload,
                isLoading: false,
            }
            break;
        case UPDATE_USER:
            return {
                ...state,
                items: null,
                item: action.payload,
                isLoading: false,
                isSaved: true
            }
            break;
    
        default:
            return state
    }
}