import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { getUser, updateUser } from '../../../../actions/userActions'
import { ScaleLoader } from 'react-spinners'
import ModalChangePassword from '../../../Components/ModalChangePassword'
import ModalCreateContract from '../../../Components/ModalCreateContract'
import UserContract from '../../../Components/UserContract'

class UserDetail extends Component {

  constructor(props) {
    super(props)
    this.state = {
      user: {
        login: "",
        email: "",
        gender: "",
        lastname: "",
        firstname: "",
        company: "",
        address: "",
        siren: "",
        postalCode: "",
        phone: "",
        city: "",
        status: ""
      }
    }

    this.updateUser = this.updateUser.bind(this)
    this.changeInput = this.changeInput.bind(this)
  }

  componentDidMount()
  {
    this.props.getUser(this.props.match.params.id)
  }

  componentWillReceiveProps(nextProp)
  {
    if(nextProp.users.item){
      var user = {... this.state.user}
      for (let k in this.state.user) {
        if(nextProp.users.item[k])user[k] =  nextProp.users.item[k]
      }
      this.setState({user: user})
    }
  }

  changeInput(e) {
    var name = e.target.name
    var value = e.target.value
    var user = {... this.state.user}
    user[name] = value
    this.setState({user})
  }

  updateUser() {
    this.props.updateUser(this.props.match.params.id, this.state.user)
  }

  render() {
    const {users, errors} = this.props
    const {user} = this.state
    
    return (
      <div className="animated fadeIn">
        <div>
          <Link className="btn btn-light" to="/users">
            <i className="fa fa-chevron-left pr-2"></i> Retour
          </Link>
        </div>
        <h1>Modifier un compte utilisateur</h1>

        <div className="row mb-5">

          <div className="col-12 col-md-6">

            <div className="form-group row">
              <label htmlFor="email" className="col-sm-3 col-form-label">Email</label>
              <div className="col-sm-9">
                <input type="text" className="form-control" name="email" id="email" placeholder="Ex: moi@exemple.com"
                  onChange={this.changeInput} value={user.email} />
                <small className="form-text text-danger">{errors.email}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="login" className="col-sm-3 col-form-label">Identifiant</label>
              <div className="col-sm-9">
                <input type="text" className="form-control" name="login" id="login"
                  onChange={this.changeInput} value={user.login} />
                <small className="form-text text-danger">{errors.login}</small>
              </div>
            </div>

            <div className="offset-sm-3 col-sm-9 text-center">
              <ModalChangePassword userId={this.props.match.params.id} />
            </div>

          </div>

          <div className="col-12 col-md-6">

          <div className="form-group row">
            <label htmlFor="gender" className="col-sm-3 col-form-label">Civilité</label>
            <div className="col-sm-9">
              <select id="gender" className="form-control" name="gender" onChange={this.changeInput} value={user.gender}>
                <option value="1">Monsieur</option>
                <option value="2">Madame</option>
              </select>
              <small className="form-text text-danger">{errors.gender}</small>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="lastname" className="col-sm-3 col-form-label">Nom</label>
            <div className="col-sm-9">
              <input type="text" className="form-control" id="lastname" name="lastname" placeholder="Ex: Payet"
                onChange={this.changeInput} value={user.lastname} />
              <small className="form-text text-danger">{errors.lastname}</small>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="firstname" className="col-sm-3 col-form-label">Prénom</label>
            <div className="col-sm-9">
              <input type="text" className="form-control" id="firstname" name="firstname" placeholder="Ex: Alexandre"
                onChange={this.changeInput} value={user.firstname} />
              <small className="form-text text-danger">{errors.firstname}</small>
            </div>
          </div>

        </div>

        </div>

        <div className="row mb-5">

          <div className="col-12 col-md-6">

            <div className="form-group row">
              <label htmlFor="company" className="col-sm-3 col-form-label">Entreprise</label>
              <div className="col-sm-9">
                <input type="text" className="form-control" id="company" name="company" placeholder="Ex: Exemple Sarl"
                  onChange={this.changeInput} value={user.company} />
                <small className="form-text text-danger">{errors.company}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="siren" className="col-sm-3 col-form-label">SIREN</label>
              <div className="col-sm-9">
                <input type="text" className="form-control" id="siren" name="siren" placeholder="Ex: 123 456 789"
                  onChange={this.changeInput} value={user.siren} />
                <small className="form-text text-danger">{errors.siren}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="phone" className="col-sm-3 col-form-label">Téléphone</label>
              <div className="col-sm-9">
                <input type="tel" className="form-control" id="phone" name="phone" placeholder="Ex: 0692 12 34 56"
                  onChange={this.changeInput} value={user.phone} />
                <small className="form-text text-danger">{errors.phone}</small>
              </div>
            </div>

          </div>

          <div className="col-12 col-md-6">

            <div className="form-group row">
              <label htmlFor="address" className="col-sm-3 col-form-label">Adresse</label>
              <div className="col-sm-9">
                <input type="text" className="form-control" id="address" name="address" placeholder="Ex: 1 rue Par Exemple"
                  onChange={this.changeInput} value={user.address} />
                <small className="form-text text-danger">{errors.address}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="postalCode" className="col-sm-3 col-form-label">Code postal</label>
              <div className="col-sm-9">
                <input type="text" className="form-control" id="postalCode" name="postalCode" placeholder="Ex: 97400"
                  onChange={this.changeInput} value={user.postalCode} />
                <small className="form-text text-danger">{errors.postalCode}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="city" className="col-sm-3 col-form-label">Ville</label>
              <div className="col-sm-9">
                <input type="text" className="form-control" id="city" name="city" placeholder="Ex: Saint Denis"
                  onChange={this.changeInput} value={user.city} />
                <small className="form-text text-danger">{errors.city}</small>
              </div>
            </div>

          </div>

        </div>

        <div className="row mb-5">

          <div className="col-12">
            <div className="form-group row">
              <label htmlFor="status" className={"col-sm-3 col-form-label text-" + (user.status == "INACTIF" ? "danger" : "success")}>Etat du compte</label>
              <div className="col-sm-9">
                <select id="status" className="form-control" name="status" onChange={this.changeInput} value={user.status}>
                  <option value="INACTIF">Non actif</option>
                  <option value="ACTIF">Actif</option>
                </select>
                <small className="form-text text-danger">{errors.status}</small>
              </div>
            </div>
          </div>

        </div>

        <div className="text-center">
          {users.isLoading ? 
            <ScaleLoader className="text-center" color={"#BB3234"} />
            : 
            <div>
              {users.isSaved && <div className="text-success mb-1"><i className="fa fa-check mr-1"></i>L'utilisateur a bien été enregistré</div>}
              <button onClick={this.updateUser} className="btn btn-lqo btn-lqo-primary">ENREGISTRER</button>

              <ModalCreateContract />
            </div>
          }
        </div>

        <UserContract userId={this.props.match.params.id} />

      </div>
    );
  }
}

UserDetail.propTypes = {
  getUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  users: state.users,
  errors: state.errors,
})

export default connect(mapStateToProps, { getUser, updateUser })(UserDetail);