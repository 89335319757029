import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { getLegalAds, createLegalAd, deleteLegalAd } from '../../../../actions/legalAdActions'
import { ScaleLoader } from 'react-spinners'
import { DateTime } from 'luxon'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

class LegalAdList extends Component {

  constructor(props) {
    super(props)
    this.state={}

    this.formatTypeCell = this.formatTypeCell.bind(this)
    this.formatActionCell = this.formatActionCell.bind(this)
  }

  componentDidMount()
  {
    this.props.getLegalAds()
  }

  formatStatusCell(status)
  {
    return status == 1 ? <span className="text-secondary">Brouillon</span> : <span className="text-success">Publié</span>
  }

  formatTypeCell(type)
  {
    let {constants} = this.props
    if(constants.items){
      let find = this.props.constants.items.typesLA.find(c => c.id == type)
      return find ? find.name : "Aucun type"
    }
    return ""
  }

  formatActionCell(id)
  {
    return <div>
      <Link className="btn btn-sm btn-lqo btn-lqo-secondary mb-1" to={"/legalads/" + id}>
        <i className="fas fa-caret-right"></i> MODIFIER
      </Link>
      <br/>
      <button className="btn btn-sm btn-lqo btn-lqo-primary"
        onClick={()=>{this.props.deleteLegalAd(id)}}>
        <i className="fas fa-caret-right"></i> SUPPRIMER
      </button>
    </div>
  }

  render() {
    const {legalAds} = this.props
    const options = {
      defaultSortName: 'createdAt',
      noDataText: 'Aucune annonce légale trouvée',
      sizePerPage: 20
    }

    return (
      <div className="animated fadeIn">
        <h1>Liste des annonces légales {legalAds.items && <em>(Totals: {legalAds.items.length})</em>}</h1>
        <div className="mb-3">
          <button className="btn btn-lqo btn-lqo-green"
            onClick={()=>{this.props.createLegalAd()}}>
            <i className="fas fa-caret-right"></i> CREER UNE NOUVELLE ANNONCE
          </button>
        </div>
        

        {legalAds.isLoading ? 
          <div className="text-center"><ScaleLoader color={"#BB3234"} /></div>
          :
          (
            <div>
              {legalAds.items && 
                <BootstrapTable containerClass="table" data={legalAds.items} bordered={false} keyField="id" options={options}
                  searchPlaceholder='Rechercher...'
                  hover pagination search condensed ignoreSinglePage>
                  <TableHeaderColumn dataField="reference" dataSort>Ref.</TableHeaderColumn>
                  <TableHeaderColumn dataField="status" dataFormat={this.formatStatusCell} dataSort>Etat</TableHeaderColumn>
                  <TableHeaderColumn dataField="type" dataFormat={this.formatTypeCell} dataSort>Type</TableHeaderColumn>
                  <TableHeaderColumn dataField="title"dataSort>Titre</TableHeaderColumn>
                  <TableHeaderColumn dataField="content" dataSort>Contenu</TableHeaderColumn>
                  <TableHeaderColumn dataField="createdAt" dataFormat={cell => DateTime.fromISO(cell).toLocaleString(DateTime.DATE_SHORT)} dataSort>Date de création</TableHeaderColumn>
                  <TableHeaderColumn dataField="id" dataFormat={this.formatActionCell}>Action</TableHeaderColumn>
                </BootstrapTable>
              }
            </div>
          )
        }
      </div>
    )
  }
}

LegalAdList.propTypes = {
  getLegalAds: PropTypes.func.isRequired,
  createLegalAd: PropTypes.func.isRequired,
  deleteLegalAd: PropTypes.func.isRequired,
  legalAds: PropTypes.object.isRequired,
  constants: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  legalAds: state.legalAds,
  constants: state.constants,
})

export default connect(mapStateToProps, { getLegalAds, createLegalAd, deleteLegalAd })(LegalAdList)