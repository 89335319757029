import axios from 'axios'
import setAuthToken from '../utils/setAuthToken'
import jwt_decode from 'jwt-decode'

import { GET_ERRORS, CLEAR_ERRORS, LOAD_USERS, GET_USERS, GET_USER, UPDATE_USER, GET_TOTAL_USER} from './types'
import { api_url } from '../config/config'

// Get all users
export const getUsers = () => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_USERS, payload: true})

    axios.get(api_url + 'users')
        .then(res => {
            dispatch({type: GET_USERS, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_USERS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Get specific user
export const getUser = (id) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_USERS, payload: true})
    
    axios.get(api_url + 'users/' + id)
        .then(res => {
            dispatch({type: GET_USER, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_USERS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Update specific user
export const updateUser = (id, data) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_USERS, payload: true})
    
    data.gender = data.gender.toString()
    axios.patch(api_url + 'users/' + id, data)
        .then(res => {
            dispatch({type: UPDATE_USER, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_USERS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Update password specific user
export const changePasswordUser = (id, data) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_USERS, payload: true})
    
    axios.patch(api_url + 'users/' + id + '/password', data)
        .then(res => {
            dispatch({type: UPDATE_USER, payload: res.data})
            //on close la modal ici
            document.getElementById('closeModalChangePassword').click()
        })
        .catch(err => {
            dispatch({type: LOAD_USERS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}


// Get all users
export const getTotalUsers = () => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_USERS, payload: true})

    axios.get(api_url + 'users/total')
        .then(res => {
            dispatch({type: GET_TOTAL_USER, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_USERS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}
