import axios from 'axios'

import { GET_ERRORS, CLEAR_ERRORS, LOAD_CONTRACTS, GET_CONTRACTS, SET_CONTRACT_PAID, CREATE_CONTRACT,  GET_USER} from './types'
import { api_url } from '../config/config'

// Get all users
export const getContractsByUser = (userId) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_CONTRACTS, payload: true})
    
    axios.get(api_url + 'contracts/users/' + userId)
        .then(res => {
            dispatch({type: GET_CONTRACTS, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_CONTRACTS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}
// Set contract paid
export const setContractPaid = (contractId, data) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_CONTRACTS, payload: true})
    
    axios.patch(api_url + 'contracts/' + contractId + '/paid', data)
        .then(res => {
            dispatch({type: SET_CONTRACT_PAID, payload: res.data.contract})
            dispatch({type: GET_USER, payload: res.data.user})
        })
        .catch(err => {
            dispatch({type: LOAD_CONTRACTS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// CREATE CONTRACT
export const createContract = (data) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_CONTRACTS, payload: true})
    
    axios.post(api_url + 'contracts', data)
        .then(res => {
            dispatch({type: CREATE_CONTRACT, payload: res.data})
            //on close la modal ici
            document.getElementById('closeModalCreateContract').click()
        })
        .catch(err => {
            dispatch({type: LOAD_CONTRACTS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Get current contracts
export const getCurrentContracts = () => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_CONTRACTS, payload: true})
    
    axios.get(api_url + 'contracts/current')
        .then(res => {
            dispatch({type: GET_CONTRACTS, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_CONTRACTS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}