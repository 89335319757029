import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ScaleLoader } from "react-spinners";
import {
  getContractsByUser,
  setContractPaid
} from "../../actions/contractActions";
import { api_url } from "../../config/config";

class UserContract extends Component {
  constructor(props) {
    super(props);
    this.getContractPDF = this.getContractPDF.bind(this);
    this.setContractPaid = this.setContractPaid.bind(this);
  }

  componentDidMount() {
    this.props.getContractsByUser(this.props.userId);
  }

  getContractPDF(id) {
    window.open(
      api_url +
        "pdf/factures/" +
        id +
        "/" +
        localStorage.getItem("jwtToken").replace("Bearer ", "")
    );
  }
  setContractPaid(id, isFree = false) {
    if (
      window.confirm(
        "Valider ce paiement passera le compte à l'état actif et mettra à jour la date de fin du contrat"
      )
    ) {
      this.props.setContractPaid(id, { isFree: isFree });
    }
  }

  render() {
    const { contracts } = this.props;
    const userContracts = [];

    contracts.items.forEach(c => {
      var offer;
      if (c.offer == 1) offer = "12 MOIS";
      else if (c.offer == 2) offer = "6 MOIS";
      else if (c.offer == 3) offer = "3 MOIS";

      if (c.alertOpt == true) offer += " + surveillance";

      var paymentType;
      if (c.paymentType == 1) paymentType = "CB";
      else if (c.paymentType == 2) paymentType = "CHÈQUE";
      else if (c.paymentType == -1) paymentType = "OFFERT";

      var paymentState = [];
      if (c.isValidated) {
        if (c.paymentType == -1) {
          paymentState = (
            <span className="text-primary">
              Offert le {new Date(c.validatedAt).toLocaleDateString()}
            </span>
          );
        } else {
          paymentState = (
            <span className="text-success">
              Payé le {new Date(c.validatedAt).toLocaleDateString()}
            </span>
          );
        }
      } else {
        paymentState = (
          <div>
            <span className="text-danger mr-3">
              <i className="fa fa-exclamation-triangle mr-2" /> NON PAYÉ
            </span>
            <button
              className="btn btn-sm btn-lqo btn-lqo-primary"
              onClick={() => {
                this.setContractPaid(c.id, false);
              }}
            >
              Valider le paiement
            </button>
            <button
              className="btn btn-sm btn-lqo btn-lqo-secondary"
              onClick={() => {
                this.setContractPaid(c.id, true);
              }}
            >
              Offrir
            </button>
          </div>
        );
      }

      var montant = parseFloat(c.price.total);
      if (c.alertOpt) {
        if (c.alertPrice) {
          montant += parseFloat(c.alertPrice.total);
        }
      }
      montant = Math.round(montant * 100) / 100;

      userContracts.push(
        <tr key={c.id}>
          <td>{c.reference}</td>
          <td>{new Date(c.createdAt).toLocaleDateString()}</td>
          <td>{c.beginAt && new Date(c.beginAt).toLocaleDateString()}</td>
          <td>{c.expiredAt && new Date(c.expiredAt).toLocaleDateString()}</td>
          <td>{offer}</td>
          <td>{paymentType}</td>
          <td>{montant} €</td>
          <td>
            {c.isValidated && (
              <button
                className="btn btn-sm btn-lqo btn-lqo-primary"
                onClick={() => {
                  this.getContractPDF(c.id);
                }}
              >
                <i className="fa fa-file-download mr-2" /> TÉLÉCHARGER
              </button>
            )}
          </td>
          <td>{paymentState}</td>
        </tr>
      );
    });

    return (
      <div>
        <h1>Historique des factures</h1>
        {contracts.isLoading ? (
          <div className="text-center">
            <ScaleLoader color={"#BB3234"} />
          </div>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Numéro de facture</th>
                <th scope="col">Créé le</th>
                <th scope="col">Début</th>
                <th scope="col">Fin</th>
                <th scope="col">Type</th>
                <th scope="col">Moyen de paiement</th>
                <th scope="col">Montant</th>
                <th scope="col">Télécharger</th>
                <th scope="col">Etat du paiement</th>
              </tr>
            </thead>
            <tbody>{userContracts}</tbody>
          </table>
        )}
      </div>
    );
  }
}

UserContract.propTypes = {
  contracts: PropTypes.object.isRequired,
  getContractsByUser: PropTypes.func.isRequired,
  setContractPaid: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  contracts: state.contracts
});

export default connect(
  mapStateToProps,
  { getContractsByUser, setContractPaid }
)(UserContract);
