import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { createContract } from '../../actions/contractActions'
import { BarLoader } from 'react-spinners';

class ModalCreateContract extends Component {

  constructor(props) {
    super(props)
    this.state={
      offer : '1',
      paymentType : '3',
      alertOpt : false,
      errors: {}
    }

    this.createContract = this.createContract.bind(this)
    this.changeInput = this.changeInput.bind(this)
  }

  changeInput(e) {
    var name = e.target.name
    var value = e.target.type == "checkbox" ? e.target.checked : e.target.value
    this.setState({
      [name] : value
    })
  }

  createContract() {
    const data = {
      user: this.props.users.item.id,
      offer: this.state.offer,
      alertOpt: this.state.alertOpt,
      paymentType: this.state.paymentType
    }

    this.props.createContract(data)
  }

  render() {
    const {users, errors, contracts, constants} = this.props

    return (
      <div className="d-inline ml-1">
        <button className="btn btn-lqo btn-lqo-secondary" data-toggle="modal" data-target="#modalCreateContract">AJOUTER UN ABONNEMENT</button>
        
        <div className="modal fade" data-backdrop="false" id="modalCreateContract" tabIndex="-1" role="dialog" >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Ajouter un abonnement à un utilisateur</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closeModalCreateContract">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-text text-muted text-left" htmlFor="offer">Choisir une offre</label>
                  <select className="form-control" id="offer" name="offer" onChange={this.changeInput}>
                    {constants.items && constants.items.offers.map(x => (
                        <option key={x.id} value={x.id}>{x.duration} mois</option>
                    ))}
                  </select>
                  <small className="form-text text-danger text-left">{errors.offer}</small>
                </div>

                {users.item &&
                  <div className="form-group form-check">
                    <input type="checkbox" className="form-check-input" name="alertOpt" id="alertOpt" onChange={this.changeInput} />
                    <label className="form-check-label" htmlFor="alertOpt">Activer les alertes</label>
                  </div>
                }

              </div>
              <div className="modal-footer">
                {
                  contracts.isLoading ? (
                    <BarLoader
                      widthUnit={"%"}
                      width={100}
                      color={'#bb3234'}
                    />) : (
                      <div>
                        <button type="button" className="btn btn-lqo-secondary mr-3" data-dismiss="modal">Fermer</button>
                        <button type="button" className="btn btn-lqo-primary" onClick={this.createContract}>Créer l'abonnement</button>
                      </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

ModalCreateContract.propTypes = {
  createContract: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  contracts: PropTypes.object.isRequired,
  constants: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  users: state.users,
  contracts: state.contracts,
  constants: state.constants,
  errors: state.errors
})

export default connect(mapStateToProps, {createContract})(ModalCreateContract);