import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { getUser } from '../../../../actions/userActions'
import { getLogsByUser } from '../../../../actions/logActions'
import { ScaleLoader } from 'react-spinners'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

class UserLog extends Component {

  constructor(props) {
    super(props)
    this.state={
      log: ''

    }

    this.formatLogToHuman = this.formatLogToHuman.bind(this)
    this.showLog = this.showLog.bind(this)
  }

  componentDidMount()
  {
    this.props.getUser(this.props.match.params.id)
    this.props.getLogsByUser(this.props.match.params.id, this.props.location.search)
  }

  formatLogToHuman(user, log)
  {
    var action
    
    //CONTROLLER USER
    if(log.controller === 'users'){

      switch (log.action) {
        case "post":
          action = 'A créé son compte'
          break;
        case "patch":
          action = 'A mis à jour ses informations'
          break;
        case "patch_:id_password":
          action = 'A modifié son mot de passe'
          break;
        case "patch_:id_password_reset":
          action = 'A réinitialisé son mot de passe'
          break;
        case "post_password_lost":
          action = 'A fait une demande de réinitialisation de son mot de passe (mot de passe oublié)'
          break;
        case "post_password_reset_:token":
          action = 'A réinitialisé son mot de passe (mot de passe oublié)'
          break;
        case "get_:id":
          action = 'A regardé ses informations personnelles'
          break;
        case "mail":
          if(!log.data.success){
            action = <span className="text-danger">{Object.values(log.data.errors).join(<br/>)} <i className="text-danger">Détail: {log.data.data}</i></span>
          }
          break;
      
        default:
          action = <span className="text-danger">Impossible de lire ce log</span>
          break;
      }

    //CONTROLLER CONTRACT
    }else if(log.controller === 'contracts'){

      switch (log.action) {
        case "post":
          action = 'A créé un nouveau contrat'
          break;
        default:
          action = <span className="text-danger">Impossible de lire ce log</span>
          break;
      }

    //CONTROLLER LOGIN
    }else if(log.controller === 'authentication'){

      switch (log.action) {
        case "post_login":
          if(log.data.success) action = "S'est connecté avec succès à la plateforme"
          else{
            action = <span className="text-danger">Échec de la connexion à la plateforme. <i className="text-danger">Détail: {Object.values(log.data.errors).join(<br/>)}</i></span>
          }
          break;
        case "post_admin_login":
          if(log.data.success) action = "S'est connecté avec succès à l'administration"
          else{
            action = <span className="text-danger">Échec de la connexion à l'administration. <i className="text-danger">Détail: {Object.values(log.data.errors).join(<br/>)}</i></span>
          }
          break;
        case "get_init_:token":
          if(log.data.success) action = "A activé son compte avec succès"
          else{
            action = <span className="text-danger">Échec de l'activation du compte. <i className="text-danger">Détail: {Object.values(log.data.errors).join(<br/>)}</i></span>
          }
          break;
        case "post_lost_password_:token":
          if(log.data.success) action = "A réinitialisé son mot de passe"
          else{
            action = <span className="text-danger">Échec de la réinitialisation du compte. <i className="text-danger">Détail: {Object.values(log.data.errors).join(<br/>)}</i></span>
          }
          break;
        default:
          action = <span className="text-danger">Impossible de lire ce log</span>
          break;
      }

    //CONTROLLER LEGALADS
    }else if(log.controller === 'legalads'){

      switch (log.action) {
        case "post_search":
          if(log.data.success) action = "A recherché des annonces légales"
          else{
            action = <span className="text-danger">Échec sur la recherche d'annonces légales <i className="text-danger">Détail: {Object.values(log.data.errors).join(<br/>)}</i></span>
          }
          break;
        default:
          action = <span className="text-danger">Impossible de lire ce log</span>
          break;
      }

    //CONTROLLER PUBLICMARKET
    }else if(log.controller === 'publicmarkets'){

      switch (log.action) {
        case "post_search":
          if(log.data.success) action = "A recherché des marchés publics"
          else{
            action = <span className="text-danger">Échec sur la recherche de marchés publics <i className="text-danger">Détail: {Object.values(log.data.errors).join(<br/>)}</i></span>
          }
          break;
        default:
          action = <span className="text-danger">Impossible de lire ce log</span>
          break;
      }

    //CONTROLLER ALERTS
    }else if(log.controller === 'alerts'){

      switch (log.action) {
        case "post":
          if(log.data.success) action = "A créé une nouvelle alerte"
          else{
            action = <span className="text-danger">Échec sur la création d'une alerte <i className="text-danger">Détail: {Object.values(log.data.errors).join(<br/>)}</i></span>
          }
          break;
        case "patch_:id":
          if(log.data.success) action = "A modifier une alerte"
          else{
            action = <span className="text-danger">Échec sur la modification d'une alerte <i className="text-danger">Détail: {Object.values(log.data.errors).join(<br/>)}</i></span>
          }
          break;
        default:
          action = <span className="text-danger">Impossible de lire ce log</span>
          break;
      }
    }else if(log.controller === 'payments'){

      switch (log.action) {
        case "post_check":
          if(log.data.success) action = "A payé son abonnement par CB"
          else{
            action = <span className="text-danger">Échec sur le paiement CB <i className="text-danger">Détail: {Object.values(log.data.errors).join(<br/>)}</i></span>
          }
          break;
        default:
          action = <span className="text-danger">Impossible de lire ce log</span>
          break;
      }

    }


    var html = 
    <div>
      {action}
      <button type="button" class="btn btn-link" data-toggle="modal" data-target="#log-modal"
      onClick={() => this.showLog(log)}
      >voir</button>

    </div>
    return html
  }

  showLog(log) {
    console.log(log)
    this.setState({
      log: log
    })
  }


  render() {
    const {users, logs} = this.props
    const options = {
      defaultSortName: 'date',
      noDataText: 'Aucun log trouvé',
      sizePerPage: 20
    }

    return (
      <div className="animated fadeIn">
        <div className="modal fade" id="log-modal" tabindex="-1" data-backdrop="false" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {this.state.log.action} sur {this.state.log.controller}
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <textarea style={{width:'100%'}} rows="30" value={JSON.stringify(this.state.log.data, null, 2)} >
                           

                </textarea>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Link className="btn btn-light" to="/users">
            <i className="fa fa-chevron-left pr-2"></i> Retour
          </Link>
        </div>
        <h1>
          Historique de l'utilisateur 
          {users.item && (
            ' ' + users.item.firstname + ' ' + users.item.lastname + " (" + users.item.company + ")"
          )}
        </h1>

        {logs.isLoading ? 
          <ScaleLoader className="text-center" color={"#BB3234"} />
          :
          (
            <div>
              {logs.items && 
                <BootstrapTable containerClass="table" data={logs.items} bordered={false} keyField="id" options={options}
                  searchPlaceholder='Votre recherche'
                  hover pagination search condensed ignoreSinglePage>
                  <TableHeaderColumn dataField="date" dataFormat={cell => (new Date(cell)).toLocaleString()} dataSort>Date</TableHeaderColumn>
                  <TableHeaderColumn dataField="name" dataFormat={this.formatLogToHuman} dataSort>Action</TableHeaderColumn>
                </BootstrapTable>
              }
            </div>
          )
        }

      </div>
    );
  }
}

UserLog.propTypes = {
  getUser: PropTypes.func.isRequired,
  getLogsByUser: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  logs: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  users: state.users,
  logs: state.logs,
})

export default connect(mapStateToProps, { getUser, getLogsByUser })(UserLog);