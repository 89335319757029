import axios from 'axios'

import { GET_ERRORS, CLEAR_ERRORS, LOAD_LOGS, GET_LOGS} from './types'
import { api_url } from '../config/config'

// Get all logs from a user
export const getLogsByUser = (id, params) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_LOGS, payload: true})
    
    axios.get(api_url + 'logs/users/' + id + params)
        .then(res => {
            dispatch({type: GET_LOGS, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_LOGS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}