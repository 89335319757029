import { LOAD_PUBLICMARKETS, GET_PUBLICMARKETS, GET_PUBLICMARKET, 
    UPDATE_PUBLICMARKET, CREATE_PUBLICMARKET, DELETE_PUBLICMARKET,
    GET_TOTAL_PUBLICMARKET } from '../actions/types'

const initialState = {
    items: [],
    item : null,
    isLoading: false,
    isSaved: false,
    total: null
}

export default function(state = initialState, action) {
    switch (action.type) {
        case LOAD_PUBLICMARKETS:
            return {
                ...state,
                item: null,
                isLoading: action.payload,
                isSaved: false
            }
            break;
        case GET_PUBLICMARKETS:
            return {
                ...state,
                items: action.payload,
                item: null,
                isLoading: false,
                isSaved: false
            }
            break;
        case GET_PUBLICMARKET:
            return {
                ...state,
                items: [],
                item: action.payload,
                isLoading: false,
                isSaved: false
            }
            break;
        case UPDATE_PUBLICMARKET:
            return {
                ...state,
                items: [],
                item: action.payload,
                isLoading: false,
                isSaved: true
            }
            break;
        case CREATE_PUBLICMARKET:
            return {
                ...state,
                items: [action.payload, ...state.items],
                isLoading: false,
                isSaved: true
            }
            break;
        case DELETE_PUBLICMARKET:
            return {
                ...state,
                items: state.items.filter(i => i.id !== action.payload),
                isLoading: false,
                isSaved: true
            }
        case GET_TOTAL_PUBLICMARKET:
            return {
                ...state,
                isLoading: false,
                total: action.payload
            }
    
        default:
            return state
    }
}