import axios from 'axios'

import { GET_ERRORS, CLEAR_ERRORS, LOAD_ADMINS, GET_ADMINS, GET_ADMIN, UPDATE_ADMIN, DELETE_ADMIN} from './types'
import { api_url } from '../config/config'

// Create specific admin
export const createAdmin = (data) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_ADMINS, payload: true})
    
    axios.post(api_url + 'admins', data)
        .then(res => {
            dispatch({type: UPDATE_ADMIN, payload: res.data})
            window.location.href = "/admins/" + res.data.id
        })
        .catch(err => {
            dispatch({type: LOAD_ADMINS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}
// Get all admins
export const getAdmins = () => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_ADMINS, payload: true})

    axios.get(api_url + 'admins')
        .then(res => {
            dispatch({type: GET_ADMINS, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_ADMINS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Get specific admin
export const getAdmin = (id) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_ADMINS, payload: true})
    
    axios.get(api_url + 'admins/' + id)
        .then(res => {
            dispatch({type: GET_ADMIN, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_ADMINS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Update specific admin
export const updateAdmin = (id, data) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_ADMINS, payload: true})
    
    axios.patch(api_url + 'admins/' + id, data)
        .then(res => {
            dispatch({type: UPDATE_ADMIN, payload: res.data})
        })
        .catch(err => {
            dispatch({type: LOAD_ADMINS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}

// Delete specific admin
export const deleteAdmin = (id) => dispatch => {

    dispatch({type: CLEAR_ERRORS, payload: {}})
    dispatch({type: LOAD_ADMINS, payload: true})
    
    axios.delete(api_url + 'admins/' + id)
        .then(res => {
            dispatch({type: DELETE_ADMIN, payload: id})
            console.log("res", res)
        })
        .catch(err => {
            console.log("errr", err)
            dispatch({type: LOAD_ADMINS, payload: false})
            dispatch({type: GET_ERRORS, payload: err.response.data})
        })
}