import React from 'react'
import { Route, Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {logoutUser} from '../../../actions/authActions'

const Menu = ({ auth, logoutUser }) => (
    auth.isAuthenticated ? (
      <div className="col-12 col-md-4 col-lg-3 menu">
        <div className="dashTitle mb-3"><span>ADMINISTRATION</span></div>
        {auth.user.role == "ROLE_SUPER_ADMIN" &&
          <div className="mb-2">
          <Link to="/admins" className="linkMenu">
            <i className="fas fa-caret-right"></i>
            Administrateurs
          </Link>
        </div>
        }
        <div className="mb-2">
          <Link to="/users" className="linkMenu">
            <i className="fas fa-caret-right"></i>
            Utilisateurs
          </Link>
        </div>
        <div>
          <Link to="/legalads" className="linkMenu">
            <i className="fas fa-caret-right"></i>
            Annonces légales
          </Link>
        </div>
        <div>
          <Link to="/publicmarkets" className="linkMenu">
            <i className="fas fa-newspaper"></i>
            Marchés Publics
          </Link>
        </div>
        <div className="pt-3">
          <a href="#" className="text-white text-center" onClick={() => {logoutUser()}}>SE DECONNECTER</a>
        </div>
      </div>
    ): (
      <div>
      </div>
    )
  
)

Menu.propTypes = {
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, {logoutUser})(Menu)
