import { SET_CURRENT_USER, DELETE_CURRENT_USER, LOAD_CURRENT_USER } from '../actions/types'
import isEmpty from '../utils/is-empty'

const initialState = {
    isAuthenticated: false,
    user : {},
    isLoading: false
}

export default function(state = initialState, action) {
    switch (action.type) {
        case LOAD_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: false,
                user: {},
                isLoading: action.payload
            }
            break;
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload,
                isLoading: false
            }
            break;
        case DELETE_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: false,
                user: {}
            }
            break;
    
        default:
            return state
    }
}