import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAdmins, deleteAdmin } from "../../../../actions/adminActions";
import { ScaleLoader } from "react-spinners";
import { DateTime } from "luxon";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

class AdminList extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.deleteHandler = this.deleteHandler.bind(this);
    this.formatActionCell = this.formatActionCell.bind(this);
  }

  componentDidMount() {
    this.props.getAdmins();
  }

  deleteHandler(id){
    if(window.confirm("Êtes-vous certain de vouloir supprimer cet administrateur ?")){
      this.props.deleteAdmin(id)
    }
  }

  formatActionCell(id) {
    return (
      <div>
        <Link
          className="btn btn-sm btn-lqo btn-lqo-secondary mb-1 mr-2"
          to={"/admins/" + id}
        >
          <i className="fas fa-caret-right" /> MODIFIER
        </Link>
        <button
          className="btn btn-sm btn-lqo btn-lqo-primary mb-1"
          onClick={() => { this.deleteHandler(id) }}
        >
          <i className="fas fa-caret-right" /> SUPPRIMER
        </button>
      </div>
    );
  }

  render() {
    const { admins } = this.props;

    const options = {
      defaultSortName: "createdAt",
      noDataText: "Aucun utilisateur trouvé",
      sizePerPage: 20
    };
    return (
      <div className="animated fadeIn">
        <h1>
          Liste des administrateurs{" "}
          {admins.items && <em>(Totals: {admins.items.length})</em>}
        </h1>
        <div className="mb-3">
          <Link className="btn btn-lqo btn-lqo-green"
            to={"/admins/create"}>
            <i className="fas fa-caret-right"></i> CREER UN NOUVEL ADMINISTRATEUR
          </Link>
        </div>

        {admins.isLoading ? (
          <div className="text-center">
            <ScaleLoader color={"#BB3234"} />
          </div>
        ) : (
          <div>
            {admins.items && (
              <BootstrapTable
                containerClass="table"
                data={admins.items}
                bordered={false}
                keyField="id"
                options={options}
                searchPlaceholder="Email, Nom, etc..."
                hover
                pagination
                search
                condensed
                ignoreSinglePage
              >
                <TableHeaderColumn dataField="role" dataSort
                 dataFormat={(role) => {
                  if(role === "ROLE_SUPER_ADMIN") return "Super Admin"
                  if(role === "ROLE_ADMIN") return "Admin"
                  else return "Inconnu"
                 }}>
                  Rôle
                </TableHeaderColumn>
                <TableHeaderColumn dataField="lastname" dataSort dataFormat={(field, u) => u.firstname + " " + u.lastname}>
                  Nom
                </TableHeaderColumn>
                <TableHeaderColumn dataField="email" dataSort>
                  Email
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="createdAt"
                  dataFormat={cell =>
                    DateTime.fromISO(cell).toLocaleString(DateTime.DATE_SHORT)
                  }
                  dataSort
                >
                  Date de création
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="id"
                  dataFormat={this.formatActionCell}
                >
                  Action
                </TableHeaderColumn>
              </BootstrapTable>
            )}
          </div>
        )}
      </div>
    );
  }
}

AdminList.propTypes = {
  getAdmins: PropTypes.func.isRequired,
  deleteAdmin: PropTypes.func.isRequired,
  admins: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  admins: state.admins
});

export default withRouter(
  connect(
    mapStateToProps,
    { getAdmins, deleteAdmin }
  )(AdminList)
);
