import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logoutUser } from "../../../actions/authActions";

class DefaultHeader extends Component {
  render() {
    const { auth } = this.props;

    return auth.isAuthenticated ? (
      <header
        id="mainHeader"
        style={{
          backgroundColor: "#BB3234",
          textAlign: "center",
          paddingTop: 15
        }}
      >
        <div style={{ height: 100, borderBottom: "1px solid white" }}>
          <div>
            <Link to="/">
              <img src="/logo.png" className="img-fluid" />
            </Link>
          </div>
        </div>

        <div className="container p-0" style={{ position: "relative" }}>
          <nav
            className="navbar navbar-expand-lg"
            style={{ backgroundColor: "#BB3234" }}
          >
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ color: "white" }}
            >
              <span className="navbar-toggler-icon">
                <i className="fa fa-bars"></i>
              </span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                {auth.user.role == "ROLE_SUPER_ADMIN" && 
                  <li className="nav-item">
                    <NavLink
                      className="nav-link text-white"
                      activeClassName="active"
                      to="/admins"
                    >
                      <i className="fa fa-users-cog mr-1"></i> Administrateurs
                    </NavLink>
                  </li>}
                <li className="nav-item">
                  <NavLink
                    className="nav-link text-white"
                    activeClassName="active"
                    to="/users"
                  >
                    <i className="fa fa-users mr-1"></i> Utilisateurs
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link text-white"
                    activeClassName="active"
                    to="/legalads"
                  >
                    <i className="fa fa-file-contract mr-1"></i> Annonces
                    légales
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link text-white"
                    activeClassName="active"
                    to="/publicmarkets"
                  >
                    <i className="fa fa-newspaper mr-1"></i> Marchés publics
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link text-white"
                    activeClassName="active"
                    to="/constants"
                  >
                    <i className="fa fa-balance-scale mr-1"></i> Tables de
                    référence
                  </NavLink>
                </li>
              </ul>
              <Link
                className="nav-link text-white"
                to="/"
                onClick={() => {
                  this.props.logoutUser();
                }}
                style={{ backgroundColor: "#962929", borderRadius: 5 }}
              >
                <i className="fa fa-power-off mr-2"></i> SE DECONNECTER
              </Link>
            </div>
          </nav>
        </div>
      </header>
    ) : (
      <div></div>
    );
  }
}

DefaultHeader.propTypes = {
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(
  connect(
    mapStateToProps,
    { logoutUser }
  )(DefaultHeader)
);
