import { LOAD_ADMINS, GET_ADMINS, GET_ADMIN, UPDATE_ADMIN, DELETE_ADMIN } from '../actions/types'

const initialState = {
    items: null,
    item : null,
    isLoading: false,
    isSaved: false,
}

export default function(state = initialState, action) {
    switch (action.type) {
        case LOAD_ADMINS:
            return {
                ...state,
                item: null,
                isLoading: action.payload,
                isSaved: false
            }
        case GET_ADMINS:
            return {
                ...state,
                items: action.payload,
                item: null,
                isLoading: false,
                isSaved: false
            }
        case GET_ADMIN:
            return {
                ...state,
                items: null,
                item: action.payload,
                isLoading: false,
                isSaved: false
            }
        case UPDATE_ADMIN:
            return {
                ...state,
                items: null,
                item: action.payload,
                isLoading: false,
                isSaved: true
            }
        case DELETE_ADMIN:
            return {
                ...state,
                items: state.items.filter(i => i.id !== action.payload),
                isLoading: false,
                isSaved: true
            }
    
        default:
            return state
    }
}