import { LOAD_LOGS, GET_LOGS } from '../actions/types'

const initialState = {
    items: null,
    isLoading: false
}

export default function(state = initialState, action) {
    switch (action.type) {
        case LOAD_LOGS:
            return {
                ...state,
                items: null,
                isLoading: action.payload
            }
            break;
        case GET_LOGS:
            return {
                ...state,
                items: action.payload,
                isLoading: false
            }
            break;
        default:
            return state
    }
}