import { LOAD_LEGALADS, GET_LEGALADS, GET_LEGALAD, UPDATE_LEGALAD, CREATE_LEGALAD, DELETE_LEGALAD, GET_TOTAL_LEGALAD } from '../actions/types'

const initialState = {
    items: [],
    item : null,
    isLoading: false,
    isSaved: false,
    total: null
}

export default function(state = initialState, action) {
    switch (action.type) {
        case LOAD_LEGALADS:
            return {
                ...state,
                item: null,
                isLoading: action.payload,
                isSaved: false,
                total: null
            }
            break;
        case GET_LEGALADS:
            return {
                ...state,
                items: action.payload,
                item: null,
                isLoading: false,
                isSaved: false
            }
            break;
        case GET_LEGALAD:
            return {
                ...state,
                items: [],
                item: action.payload,
                isLoading: false,
                isSaved: false
            }
            break;
        case UPDATE_LEGALAD:
            return {
                ...state,
                items: [],
                item: action.payload,
                isLoading: false,
                isSaved: true
            }
            break;
        case CREATE_LEGALAD:
            return {
                ...state,
                items: [action.payload, ...state.items],
                isLoading: false,
                isSaved: true
            }
            break;
        case DELETE_LEGALAD:
            return {
                ...state,
                items: state.items.filter(i => i.id !== action.payload),
                isLoading: false,
                isSaved: true
            }
        case GET_TOTAL_LEGALAD:
            return {
                ...state,
                isLoading: false,
                total: action.payload
            }
            
    
        default:
            return state
    }
}