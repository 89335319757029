import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { getPublicMarket, updatePublicMarket } from '../../../../actions/publicMarketActions'
import { ScaleLoader } from 'react-spinners'
import { DateTime } from 'luxon'
import { image_url } from '../../../../config/config'
import storageHelper from "../../../../utils/storageHelper";
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

class PublicMarketDetail extends Component {

  constructor(props) {
    super(props)
    this.state = {
      status: 1,
      categories: [],
      type: "",
      title: "",
      source: 1,
      newspaperPublishedAt: "",
      emittor: "",
      location: "",
      webPublishedAt: "",
      expiredAt: "",
      content: "",
      price: "",
      image: "",
      createdAt: null,
      preview: {
        status: "",
        categories: [],
        type: "",
        title: "",
        legalForm: "",
        source: "",
        location: "",
        newspaperPublishedAt: "",
        emittor: "",
        webPublishedAt: "",
        expiredAt: "",
        content: "",
        price: "",
        image: "",
      }
    }

    this.updatePublicMarket = this.updatePublicMarket.bind(this)
    this.changeInput = this.changeInput.bind(this)
    this.updatePreview = this.updatePreview.bind(this)
    this.publishPublicMarket = this.publishPublicMarket.bind(this)
  }

  componentDidMount()
  {
    this.props.getPublicMarket(this.props.match.params.id)
  }

  componentWillReceiveProps(nextProp)
  {
    if(nextProp.constants.items && nextProp.publicMarkets.item) {

      this.setState(
        nextProp.publicMarkets.item
      )
      this.setState({
        preview: nextProp.publicMarkets.item, 
      })
    }
  }

  changeInput(e) {
    var name = e.target.name
    var value = e.target.value
    var state = this.state
    if(name=='type') {
      if(value == '1' || value == '221') {
        state.price = 0
      }
    }else if(name == "categories"){
      if(e.target.checked){
        state.categories.push(value)
      }else{
        state.categories = state.categories.filter(x => x != value)
      }
      value = state.categories

    }

    state[name] = value
    this.setState(state)
  }

  changeContent(content) {
    this.setState({content})
  }

  updatePublicMarket() {
    this.props.updatePublicMarket(this.props.match.params.id, this.state)
  }
  publishPublicMarket() {
    this.setState({
      status : 2
    }, function() {
      this.updatePublicMarket()
    })
    //this.props.updatePublicMarket(this.props.match.params.id, this.state)
  }

  updatePreview() {
    let preview = { ...this.state }
    delete preview.preview
    this.setState({
      preview
    })
  }

  render() {
    const {publicMarkets, errors, constants} = this.props
    
    let categories = []
    let categoriesPreview = []
    let types = []
    let emittors = []
    let locations = []
    let adsources = []
    let prices = []

    let isAvisDAttribution = false
    if(this.state.type == 6){
      isAvisDAttribution = true
    }

    if(constants.items){

      if(constants.items.categoriesMP) {
        constants.items.categoriesMP.forEach((c,k) => {
          var isChecked = this.state.categories.find(x => c.id.toString() == x)
          categories.push(
            <div className="form-check form-check-inline" key={'categories_' + c.id}>
              <input className="form-check-input" type="checkbox" id={'categories_' + c.id} 
                name="categories" value={c.id} onChange={this.changeInput} checked={isChecked ? "checked" : ""} />
              <label className="form-check-label" htmlFor={'categories_' + c.id}>{c.name}</label>
            </div>
          )
         
          if(this.state.preview.categories.find(x => c.id.toString() == x)){
            categoriesPreview.push(c.name)
          }
        })
      }
      
      if(constants.items.typesMP) {
        constants.items.typesMP.forEach((element,k) => {
          types.push(<option key={k} value={element.id}>{element.name}</option>)
        });
      }
  
      if(constants.items.emittors) {
        constants.items.emittors.forEach((el,k) => {
          emittors.push(<option key={k} value={el.id}>{el.name}</option>)
        })
      }
  
      if(constants.items.locations) {
        constants.items.locations.forEach((el,k) => {
          locations.push(<option key={k} value={el.id}>{el.name}</option>)
        })
      }
  
      if(constants.items.sources) {
        constants.items.sources.forEach((el,k) => {
          adsources.push(<option key={k} value={el.id}>{el.name}</option>)
        })
      }
  
      if(constants.items.sources) {
        constants.items.prices.forEach((el,k) => {
          prices.push(<option key={k} value={el.id}>{el.name}</option>)
        })
      }
    }

    let errorsDiv = ''
    if(Object.keys(errors).length != 0 && errors.constructor === Object) {
      console.log('errors', errors)
      errorsDiv = <div style={{marginTop:15, color:'#e41619'}}>Le formulaire comporte des erreurs</div>
    }
    
    return (
      <div className="animated fadeIn">
        <div>
          <Link className="btn btn-light" to="/publicmarkets">
            <i className="fa fa-chevron-left pr-2"></i> Retour
          </Link>
        </div>
        <h1>Editer un marché public</h1>

        <div className="row mb-5">

          <div className="col-md-6">
          <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">Logo</label>
              <div className="col-sm-9">
                { this.state.image != "" ? 
                (<img 
                style={{maxHeight:100}}
                src={storageHelper(this.state)} />
                ) : (<span className="form-text">aucune image</span>)
              }
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">Etat de la publication</label>
              <div className="col-sm-9">
                <select id="status" className="form-control" name="status" onChange={this.changeInput} value={this.state.status}>
                  <option value="1">Brouillon</option>
                  <option value="2">Publié</option>
                </select>
                <small className="form-text text-danger">{errors.status}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">Catégories</label>
              <div className="col-sm-9">
                  {categories}
                <small className="form-text text-danger">{errors.categories}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">Type</label>
              <div className="col-sm-9">
                <select id="type" className="form-control" name="type" onChange={this.changeInput} value={this.state.type}>
                  <option value="">...</option>
                  {types}
                </select>
                <small className="form-text text-danger">{errors.type}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="emittor" className="col-sm-3 col-form-label">Emetteur</label>
              <div className="col-sm-9">
                <select id="emittor" className="form-control" name="emittor" onChange={this.changeInput} value={this.state.emittor}>
                  <option value="">...</option>
                  {emittors}
                </select>
                <small className="form-text text-danger">{errors.emittor}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="location" className="col-sm-3 col-form-label">Lieu</label>
              <div className="col-sm-9">
                <select id="location" className="form-control" name="location" onChange={this.changeInput} value={this.state.location}>
                  <option value="">...</option>
                  {locations}
                </select>
                <small className="form-text text-danger">{errors.location}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">Titre</label>
              <div className="col-sm-9">
                <input id="title" className="form-control" name="title" onChange={this.changeInput} value={this.state.title} />
                <small className="form-text text-danger">{errors.title}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">Source</label>
              <div className="col-sm-9">
                <select id="source" className="form-control" name="source" onChange={this.changeInput} value={this.state.source}>
                  <option value="">...</option>
                  {adsources}
                </select>
                <small className="form-text text-danger">{errors.source}</small>
              </div>
            </div>

            {
              !isAvisDAttribution && 
                        <div className="form-group row">
                          <label htmlFor="status" className="col-sm-3 col-form-label">Valeur</label>
                          <div className="col-sm-9">
                            <select id="price" className="form-control" name="price" onChange={this.changeInput} value={this.state.price}>
                              <option value="">...</option>
                              {prices}
                            </select>
                            <small className="form-text text-danger">{errors.price}</small>
                          </div>
                        </div>
            }
            
            <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">Parution journal</label>
              <div className="col-sm-9">
                <input type="date" id="newspaperPublishedAt" className="form-control" name="newspaperPublishedAt" 
                  value={this.state.newspaperPublishedAt && (DateTime.fromISO(this.state.newspaperPublishedAt)).toISO().substr(0, 10)} 
                  onChange={this.changeInput} />
                <small className="form-text text-danger">{errors.newspaperPublishedAt}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">Parution web</label>
              <div className="col-sm-9">
                <input type="date" id="webPublishedAt" className="form-control" name="webPublishedAt" 
                  value={this.state.webPublishedAt && (DateTime.fromISO(this.state.webPublishedAt)).toISO().substr(0, 10)}
                  onChange={this.changeInput} />
                <small className="form-text text-danger">{errors.webPublishedAt}</small>
              </div>
            </div>

            {
              !isAvisDAttribution && 
                <div className="form-group row">
                  <label htmlFor="status" className="col-sm-3 col-form-label">Date d'expiration </label>
                  <div className="col-sm-9">
                    <input type="date" id="expiredAt" className="form-control" name="expiredAt" 
                      value={this.state.expiredAt ? (DateTime.fromISO(this.state.expiredAt)).toISO().substr(0, 10) : ""}
                      onChange={this.changeInput} />
                    <small className="form-text text-danger">{errors.expiredAt}</small>
                  </div>
                </div>
            }

            <div className="form-group row">
              <label htmlFor="status" className="col-sm-3 col-form-label">Contenu</label>
              <div className="col-sm-9">
                {/* <textarea rows="10" id="content" className="form-control" name="content" onChange={this.changeInput} value={this.state.content}></textarea> */}
                <CKEditor
                    editor={ ClassicEditor }
                    data={this.state.content}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        this.changeContent(data)
                    } }
                />
                <small className="form-text text-danger">{errors.content}</small>
              </div>
            </div>

    

          </div>

          <div className="col-md-6">

            <div className="preview">
              <div className="form-group row">
                <label htmlFor="status" className="col-sm-5 col-form-label">Logo</label>
                <div className="col-sm-7">
                  { this.state.preview.image != "" ? 
                  (<img 
                  style={{maxHeight:100}}
                  src={storageHelper(this.state)} />
                  ) : (<span className="form-text">aucune image</span>)
                }
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="status" className="col-sm-5 col-form-label">Etat de la publication</label>
                <div className="col-sm-7">
                  {(this.state.preview.status == 2) ? 'Publié' : 'Brouillon'}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="category" className="col-sm-5 col-form-label">Catégories</label>
                <div className="col-sm-7">
                  {categoriesPreview.join(', ')}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="type" className="col-sm-5 col-form-label">Type</label>
                <div className="col-sm-7">
                  {(constants.items && constants.items.typesMP && this.state.preview.type) ? constants.items.typesMP.find(x=> x.id == this.state.preview.type).name : ""}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="reference" className="col-sm-5 col-form-label">Référence</label>
                <div className="col-sm-7">
                  {this.state.preview.reference}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="emittor" className="col-sm-5 col-form-label">Emetteur</label>
                <div className="col-sm-7">
                  {(constants.items && constants.items.emittors && this.state.preview.emittor) ? constants.items.emittors.find(leg=> leg.id == this.state.preview.emittor).name : ""}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="location" className="col-sm-5 col-form-label">Lieu</label>
                <div className="col-sm-7">
                  {(constants.items && constants.items.locations && this.state.preview.location) ? constants.items.locations.find(leg=> leg.id == this.state.preview.location).name : ""}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="title" className="col-sm-5 col-form-label">Titre</label>
                <div className="col-sm-7">
                  {this.state.preview.title}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="source" className="col-sm-5 col-form-label">Source</label>
                <div className="col-sm-7">
                  {(constants.items && constants.items.sources && this.state.preview.source) ? constants.items.sources.find(sour=> sour.id == this.state.preview.source).name : ""}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="price" className="col-sm-5 col-form-label">Valeur</label>
                <div className="col-sm-7">
                  {(constants.items && constants.items.prices && this.state.preview.price) ? constants.items.prices.find(p=> p.id == this.state.preview.price).name : ""}
                </div>
              </div>
              
              <div className="form-group row">
                <label htmlFor="newspaperPublishedAt" className="col-sm-5 col-form-label">Parution journal</label>
                <div className="col-sm-7">
                  {DateTime.fromISO(this.state.preview.newspaperPublishedAt).toLocaleString(DateTime.DATE_SHORT)}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="webPublishedAt" className="col-sm-5 col-form-label">Parution web</label>
                <div className="col-sm-7">
                  {DateTime.fromISO(this.state.preview.webPublishedAt).toLocaleString(DateTime.DATE_SHORT)}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="expiredAt" className="col-sm-5 col-form-label">Date d'expiration</label>
                <div className="col-sm-7">
                  {DateTime.fromISO(this.state.preview.expiredAt).toLocaleString(DateTime.DATE_SHORT)}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="content" className="col-sm-5 col-form-label">Contenu</label>
                <div className="col-sm-7">
                  <div dangerouslySetInnerHTML={{ __html: this.state.preview.content }} />
                </div>
              </div>
              <div style={{textAlign:'center'}}>
                <button onClick={this.updatePreview} className="btn btn-lqo btn-lqo-primary">Mettre à jour l'aperçu</button>
              </div>
            </div>
            
          </div>

        </div>

        <div className="text-center">
          {
            
            publicMarkets.isLoading ? 
              (<div className="text-center"><ScaleLoader color={"#BB3234"} /></div>) 
              : 
              (
                <div>
                  {publicMarkets.isSaved && <div className="text-success mb-1"><i className="fa fa-check mr-1"></i>Le marché public a bien été enregistré</div>}
                  <button onClick={this.updatePublicMarket} className="btn btn-lqo btn-lqo-primary">ENREGISTRER{this.state.status == 1 && " LE BROUILLON"}</button>  
                  {
                    this.state.status == 1 && 
                    <button onClick={this.publishPublicMarket} className="btn btn-lqo btn-lqo-secondary ml-1">PUBLIER</button>  
                  }
                </div>     
              )
          }
          {errorsDiv}
        </div>

      </div>
    );
  }
}

PublicMarketDetail.propTypes = {
  getPublicMarket: PropTypes.func.isRequired,
  updatePublicMarket: PropTypes.func.isRequired,
  publicMarkets: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  constants: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  publicMarkets: state.publicMarkets,
  errors: state.errors,
  constants: state.constants,
})

export default connect(mapStateToProps, { getPublicMarket, updatePublicMarket })(PublicMarketDetail);