import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ConstantEditor from "../../../Components/ConstantEditor";
import Axios from "axios";
import { api_url } from "../../../../config/config";
import { ScaleLoader } from "react-spinners";

class ConstantDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cst: false,
      isLoading: false
    };
    this.editable = [
      { name: "legalForms", label: "Formes légales" },
      { name: "sources", label: "Sources" },
      { name: "typesLA", label: "Types annonces légales" },
      { name: "typesMP", label: "Types marchés publics" },
      { name: "emittors", label: "Émetteurs" },
      { name: "locations", label: "Villes" }
    ];
    this.updateCst = this.updateCst.bind(this);
    this.addCst = this.addCst.bind(this);
    this.removeCst = this.removeCst.bind(this);
    this.save = this.save.bind(this);
    this.checkDuplicateId = this.checkDuplicateId.bind(this);
  }

  componentDidMount() {
    if (this.props.constants.items) {
      this.setState({ cst: this.props.constants.items });
    }
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp.constants.items) {
      this.setState({ cst: nextProp.constants.items });
    }
  }

  updateCst(key, itemKey, value, type) {
    var { cst } = this.state;
    if (type == "id") {
      cst[key][itemKey].id = value;
    } else {
      cst[key][itemKey].name = value;
    }
    this.setState({ cst });
  }

  addCst(key) {
    var { cst } = this.state;
    var max = Math.max.apply(Math, cst[key].map(o => parseInt(o.id)));
    cst[key].push({
      name: "",
      id: max + 1,
      new: true
    });
    this.setState({ cst }, function() {
      document.getElementById("cst" + key).lastChild.scrollIntoView();
      document.getElementById("cst" + key).lastChild.firstChild.focus();
    });
  }
  removeCst(key, itemKey) {
    var { cst } = this.state;
    cst[key] = cst[key].filter((c, k) => k !== itemKey);
    this.setState({ cst });
  }

  checkDuplicateId() {
    var { cst } = this.state;
    var hasDup = false;
    for (var key in cst) {
      if (key == "id") continue;
      cst[key].forEach((cs, k) => {
        if (cst[key].find((c, kk) => c.id == cs.id && k != kk)) {
          alert(
            "ID " +
              cs.id +
              " dupliqué dans la constante " +
              this.editable.find(edit => edit.name == key).label +
              ".\n Les ID d'une même constante doivent être uniques !"
          );
          hasDup = true;
        }
      });
    }
    console.log(hasDup);
    if (!hasDup) {
      this.save();
    }
  }

  save() {
    this.setState({ isLoading: true });
    Axios.patch(api_url + "constants", this.state.cst)
      .then(res => {
        console.log(res.data);
        this.setState({ isLoading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { cst, isLoading } = this.state;

    var content = [];
    if (cst) {
      console.log("cst", cst);
      for (let key in cst) {
        if (!this.editable.find(edit => edit.name == key)) continue;
        content.push(
          <ConstantEditor
            key={"cstblk" + key}
            label={this.editable.find(edit => edit.name == key).label}
            varName={key}
            dataArray={cst[key]}
            updateCst={this.updateCst}
            addCst={this.addCst}
            removeCst={this.removeCst}
          />
        );
      }
    }

    return (
      <div className="animated fadeIn">
        <div>
          <Link className="btn btn-light" to="/">
            <i className="fa fa-chevron-left pr-2"></i> Retour
          </Link>
        </div>
        <h1>Editer les tables de référence</h1>

        <div className="row">{content}</div>
        {isLoading ? (
          <ScaleLoader color={"#BB3234"} />
        ) : (
          <button className="btn btn-success" onClick={this.checkDuplicateId}>
            Enregistrer
          </button>
        )}
      </div>
    );
  }
}

ConstantDetail.propTypes = {
  errors: PropTypes.object.isRequired,
  constants: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  errors: state.errors,
  constants: state.constants
});

export default connect(
  mapStateToProps,
  {}
)(ConstantDetail);
